import React from 'react'

function CareerOne() {
    return (
        <div className='container mb-4'>
            <div className="section_heading">
                <h1>Kalpataru <span> Carrers </span> </h1>
            </div>
            <div className='row'>
                <div className='col franchise_title'>
                    YOUTH EMPLOYMENT/BRIGHT FUTURE OF THE YOUTH
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col col summer_camp_p'>
                    <p>
                        Youth is the backbone of the nation. Unfortunately, some of them are turning addicted to drugs, spoiling their intelligence, debilitating their youth, and the nation. The addiction to drugs is leading to their rebellious behavior, thereby causing large-scale arson, violence disturbing the peace in the society and spoiling their lives. The reason is,the drugs that were consumed disturb the cells in the brain. To bring a radical change in the youth, we formed divisions that deal with the youth employability solutions and traditional business solutions, and make a concentrated effort to change their mindset, and travel in a developmental path.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CareerOne