import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import CareerOne from '../Container/CareerOne'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

function Career() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Careers" />
            <TopNav />
            <Breadcrumb image="images/about/herb.jpg" title="Home" content="Careers" />
            <CareerOne />
            <Footer />
        </React.Fragment>
    )
}

export default Career