import React, { useState } from 'react'
import { Col, Container, Form, Row, Button, InputGroup } from 'react-bootstrap'

export default function HomeContact() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <Container className="mt-5" >
            <Container className="home-contact-container" >
                <Row >
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} >
                        <div>
                            <h3 className="home-contact-title"> Contact us </h3>
                            <h2 className="home-contact-title-one"> Get in touch with us!  </h2>
                        </div>
                        <div className="home-contact-list">
                            <ul>
                                <li>
                                    <img className="home-contact-img" src="images/icons/phone.png" alt="Loading.." />
                                    <a href="tel:+1608-235-2485" > +919515621818</a>
                                </li>
                                <li>
                                    <img className="home-contact-img" src="images/icons/email.png" alt="Loading.." />
                                    <a href="tel:+1608-235-2485" > info@kalpataru.com </a>
                                </li>
                                <li>
                                    <img className="home-contact-img" src="/images/icons/home.png" alt="Loading.." />
                                    <a>Shashank Avas Apt #21, VidyaRanyapura,Banglore City, Banglore-560097</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12} className="home-contact-form-col" >
                        <Row> <h3 className="home-contact-title-one text-center"> Book Appointment </h3> </Row>
                        <Form className="mb-1 justify-content-center contact-form" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-1 justify-content-center">
                                <Form.Group className="p-2" as={Col} xl={5} controlId="validationCustom01">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid First Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="p-2" as={Col} xl={5} controlId="validationCustom01">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Mobile Number"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid First Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2 justify-content-center">
                                <Form.Group className="p-2" as={Col} xl={5} controlId="validationCustom02">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Email"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Email.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="p-2" as={Col} xl={5} controlId="validationCustom03">
                                    <Form.Label>Appointment Date</Form.Label>
                                    <Form.Control type="date" placeholder="Select Date" required />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a Date.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 justify-content-center">
                                <Form.Group as={Col} xl={10} controlId="validationCustom04">
                                    <Form.Label>Select Branch</Form.Label>
                                    <Form.Select aria-label="Default select example">
                                        <option>Select Location</option>
                                        <option value="1">Malkajgiri</option>
                                        <option value="2">Kothapet</option>
                                        <option value="3">Suncity</option>
                                        <option value="3">Nizamabad</option>
                                        <option value="3">Karimnagar</option>
                                        <option value="3">Kamareddy</option>
                                        <option value="3"> Siddipet </option>
                                        <option value="3">Bandlaguda</option>
                                        <option value="3">Attapur</option>
                                        <option value="3">Mehidipatnam</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Comment.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 justify-content-center">
                                <Form.Group as={Col} xl={10} controlId="validationCustom04">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control as="textarea" placeholder="Enter Comment" required />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Comment.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 justify-content-center">
                                <Col xl={10} >
                                    <Button className="home-contact-btn justify-content-center" type="submit">Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
