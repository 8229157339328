import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import AboutStore from '../Container/AboutKalpataruStores/AboutStore'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function AboutKalpataruStores() {
    return (
        <React.Fragment>
        <SEO title="Kalpataru || About Kalpataru Stores"  />
        <TopNav/>
        <Breadcrumb image="/images/breadcrumb/banner02.jpg" title="Home" content="About Kalpataru Stores"/>
        <AboutStore/>
        <Footer/>
       </React.Fragment>
    )
}
