import React from 'react'
import Breadcrumb from '../../BreadCrumb/BreadCrumb'
import YogaCampOne from '../../Container/EventsContainer/YogaCampOne'
import YogaCampTwo from '../../Container/EventsContainer/YogaCampTwo'
import Footer from '../../Container/Footer/Footer'
import SEO from '../../Container/SEO/SEO'
import TopNav from '../../Container/TopNav/TopNav'

export default function YogaCamp() {
    return (
       <React.Fragment>
        <SEO title="Kalpataru || Yoga Camp"  />
        <TopNav/>
        <Breadcrumb image="/images/breadcrumb/banner03.jpg" title="Home" content="Yoga Camp"/>
        <YogaCampOne/>
        <YogaCampTwo/>
        <Footer/>
       </React.Fragment>
    )
}
