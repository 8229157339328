const validateEmail = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// const validatePassword = RegExp(
//   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/
// );
const mobile = RegExp(
  /([0-9])/
);
export default function RegisterValidation(values) {
    let errors = {};

    if (!values.fullName) {
        errors.name = "Enter Your Full Name";
    } else if (values.fullName.length < 6) {
        errors.fullName = "Name should contain minimum 6 characters";
    }

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!validateEmail.test(values.email)) {
        errors.email = "Email is invalid";
    }

    if (!values.gender) {
        errors.gender = "This Field is Required";
    } 

    if (!values.fatherName) {
        errors.fatherName = "This Field is Required";
    } 

    if (!values.motherName) {
        errors.motherName = "This Field is Required";
    } 

    if (!values.gaurdianName) {
        errors.gaurdianName = "This Field is Required";
    } 

    if (!values.dateOfBirth) {
        errors.dateOfBirth = "Enter Date of Birth";
    } 

    if (!values.age) {
        errors.age = "This Field is Required";
    } 

    if (!values.classStudy) {
        errors.classStudy = "This Field is Required";
    } 

    if (!values.motherTongue) {
        errors.motherTongue = "This Field is Required";
    } 

    if (!values.motherOcupation) {
        errors.motherOcupation = "This Field is Required";
    } 

    if (!values.fatherOccupation) {
        errors.fatherOccupation = "This Field is Required";
    } 

    if (!values.interest) {
        errors.interest = "This Field is Required";
    } 

    if (!values.extraActivities) {
        errors.extraActivities = "This Field is Required";
    } 

    if (!values.otherCampBefore) {
        errors.otherCampBefore = "This Field is Required";
    } 

    if (!values.mobile) {
        errors.mobile = "Enter Your Mobile Number";
    } else if (values.mobile.length < 10) {
        errors.mobile = "Mobile Number Should Contain Minimum 10 Characters ";
    } else if (values.mobile.length > 10) {
        errors.mobile = "Mobile Number Should Contain Maximum 10 Characters ";
    } else if (!mobile.test(values.mobile)) {
        errors.mobile = "Enter Valid Mobile Number";
    }

    if (!values.mobileOne) {
        errors.mobileOne = "Enter Your Mobile Number";
    } else if (values.mobileOne.length < 10) {
        errors.mobileOne = "Mobile Number Should Contain Minimum 10 Characters ";
    } else if (values.mobileOne.length > 10) {
        errors.mobileOne = "Mobile Number Should Contain Maximum 10 Characters ";
    } else if (!mobile.test(values.mobileOne)) {
        errors.mobileOne = "Enter Valid Mobile Number";
    }

    if (!values.camps) {
        errors.camps = "This Field is Required";
    } 

    
    if (!values.northCamps) {
        errors.northCamps = "This Field is Required";
    } 

    
    if (!values.southCamps) {
        errors.southCamps = "This Field is Required";
    } 

    if (!values.address) {
        errors.address = "Enter Your Address";
    } 

    if (!values.state) {
        errors.state = "Select Your State";
    } 

    if (!values.city) {
        errors.city = "Select Your City";
    } 

    if (!values.pincode) {
        errors.pincode = "Enter Your Pincode";
    } 
   
    return errors;
}
