import React from 'react'

function CoursesOne() {
    return (
        <div className='container mb-3' >
            <div className="section_heading">
                <h1>Register For <span> Courses </span> </h1>
            </div>
            <div className="row">
                <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                    <p>BNYS Full Form is Bachelor of Naturopathy and Yogic Science. BNYS is 5.5 years undergraduate degree focussing on naturopathy and the use of yoga in treating bodily ailments. Students must complete 1 year of mandatory internship after completing the 4.5 Year UG course.</p>
                    <p>BAMS stands for Bachelor of Ayurvedic Medicine and Surgery. BAMS is an undergraduate degree programme designed to make students familiar with the concepts of Ayurveda and use them for the treatment of patients. BAMS incorporates Ayurveda with the ideas of modern medicines, and students are taught a combination of both as part of the course curriculum. </p>
                </div>
            </div>
            <div className='row' >
                <div className='col-xl-6 mr-2' >
                    <img className='homeabout-image' src='/images/other/course.jpeg' alt="Loading.." />
                </div>
                <div className='col-xl-6 ml-2 mt-2' >
                    <img className='homeabout-image' src='/images/other/course1.jpeg' alt="Loading.." />
                </div>
            </div>
        </div>
    )
}

export default CoursesOne