import React from 'react'
import { Link } from 'react-router-dom'
import TeamData from '../../../Data/AboutData/MedicalTeam.json'

export default function MedicalTeamOne() {
    return (
        <div className='container-fluid' >
            <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Medical</span> Team</h1>
                    </div>
                </div>
            </div>
            <div className='row' >
                <div className='col medical-team-col ' >
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </div>
            </div>
            <div className='row' style={{ 'justifyContent': 'center' }} >
                {
                    TeamData.map((d) => (
                        <div className='col-xl-9' >
                            <div className="card mb-3" >
                                <div className="row" >
                                    <div className="col-xl-3 col-md-3">
                                        <img src={d.image} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-xl-9 col-md-9">
                                        <div className="card-body">
                                            <h5 className="card-title medical-team-title"> {d.name} </h5>
                                            <h6 className='medical-team-text' > {d.text} </h6>
                                            <p className="card-text medical-team-des "> {d.des} </p>


                                            <Link className='medical-team-link' > {d.link} </Link>
                                            {/* <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 mb-5'>
                                <p className="card-text medical-team-des "> {d.des1} </p>
                                <p className="card-text medical-team-des "> {d.des2} </p>
                                <p className="card-text medical-team-des "> {d.des3} </p>
                                <p className="card-text medical-team-des "> {d.des4} </p>
                                <p className="card-text medical-team-des "> {d.des5} </p>
                                <p className="card-text medical-team-des "> {d.des6} </p>
                            </div>
                            <div className='mt-5 mb-5' style={{ borderTop: '1px solid gray', width: '100%' }} >  </div>

                        </div>
                    ))
                }

            </div>
        </div>
    )
}
