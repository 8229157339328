import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Back2() {
    return (
        <div>
            <div className="container homeabout-main pt-5">
                <div className="row justify-content-between">
                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> <b>Get Relief From Back Pain And Lower Back Pain Naturally And Permanently Without Surgery Through Kalpataru Ayurvedic Therapies</b></p>
                        <p> ➤ Back pain is one of the most common ailments, is widely prevalent which affects lower portion of your spine (back bone). Almost everyone suffers with low back pain in their life time.  </p>
                        <p> ➤ Functions of the low back/lumbar include structural support, movement & protection of certain body tissues.</p>
                        <p> ➤ The back, a complex structure of muscles, bone and elastic tissue is known as the life bone of the body. The core of our back (spine) is composed of 24 bones, piled one on top of the other.</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP7PThb74NpNHj67-PmAsVmmKR08kemhyEPA&usqp=CAU" height="250px" alt="Loading.." />
                    </div>
                </div>
            </div>
        </div>



    )
}
