import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from 'swiper';
import HomeFacilitiesData from "../../../Data/HomeData/HomeFacilities.json"
import { Link } from 'react-router-dom';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

export default function HomeFacilities() {
    return (
        <div className='container homefacilities-container mt-5 ' >
            <div className="row" >
              {/*   <div className="col" >
                    <h3 className="homefacility-main-title" >Kalpataru Events </h3>
                </div> */}
                  <div className="section_heading">
                <h1> Kalpataru <span> Events </span> </h1>
            </div>
            </div>
            <div className="row" >
                <div className="col" >
                    <Swiper spaceBetween={50} loop={true} loopFillGroupWithBlank={true}
                        pagination={{ "clickable": true }}
                        navigation={true} className="homefacility-slider"
                        breakpoints={{
                            1200: {
                                slidesPerView: 3
                            },
                            760: {
                                width: 760,
                                slidesPerView: 2
                            },
                            575: {
                                width: 575,
                                slidesPerView: 3
                            },
                            360: {
                                width: 360,
                                slidesPerView: 1
                            }

                        }}
                    >
                        {
                            HomeFacilitiesData.map((data) => (
                                <Swiper slidesPerView={3} spaceBetween={50} pagination={{
                                    "clickable": true
                                }} className="mySwiper"
                                    breakpoints={{

                                        575: {
                                            slidesPerView: 3
                                        }
                                    }}
                                >
                                    <SwiperSlide className="homefacility-slide" >
                                        <img className="slide-img" src={data.image} />
                                        <h3 className="homefacility-title" > {data.title} </h3>
                                        <p className="homefacility-text" > {data.text} </p>
                                        <Link to={data.link} className="homefacility-link" >Know more</Link>
                                    </SwiperSlide>
                                </Swiper>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
