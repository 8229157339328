import React from 'react'
import data from "../../../Data/ProgressData.json";

export default function Progress() {
    return (
        <div className="container progress-container">
            <div className="row">
                {/* <div className="col align-self-center">
                    <div className="p-3 progress-title ">Our Achievements</div>
                </div> */}
                {
                    data.map((d) => (
                        <div className="col-xl-3 col-lg-3 col-xs-10 col-sm-10 col-md-10 col_center mt-2 ">
                            <div className="p-3 border bg-light">
                                <div className="row" >
                                    <div className="col-3 mt-3" >
                                        <img className="prog-logo" src={d.image} alt="logo" />
                                    </div>
                                    <div className="col-9" >
                                        <h6 className="progress-title-one"> {d.text} </h6>
                                        <p className="progress-time" > {d.title}  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {/* <div className="col-xl-3 col-lg-3 col-xs-10 col-sm-10 col-md-10 col_center mt-2 ">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="/images/home/branch.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 4 </h6>
                                <p className="progress-time" >Total States  </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col_center mt-2">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="/images/home/experience.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 22 </h6>
                                <p className="progress-time" > Years of Excellence </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col_center mt-2">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="/images/home/hospital-bed.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 40 </h6>
                                <p className="progress-time" > Years Exp Doctors  </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col_center mt-2">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="/images/home/staff.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 100% </h6>
                                <p className="progress-time" > Satisfied Clients  </p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <div className="row" >
                <div className="col">
                    <div className="p-3 progress-title text-center ">
                        <Link className="about-link" to="/progresss" >Know More</Link>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
