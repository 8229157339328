import React from 'react'

function ProductOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>Buy <span> Pts </span> Online</h1>
            </div>
           
        </div>
    )
}

export default ProductOne