import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';



export default function Treatment3() {
    return (

        <div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">About SciaticaPain :-</h5>
                            <p className="para-text">  ➤ Herniated disc, bone spur or narrowing of the spine ( spinal Stenosis) in the lower back, compresses part of the sciatic nerve causing inflammation and pain to radiate along the nerve and often some numbness in the affected leg called the sciatica or lumbar radiculopathy.</p>
                            <p className="para-text">  ➤ Sciatica resembles the disease gridhrasi of the ayurveda. Gridhrasi is one among the vataja nanatmaja Vyadhi, where dysfunction of vata effect gridhrasi nadi characterized by low back pain radiating to lower limbs, stiffness and pricking type of pain.</p>
                            <h5 className='app-heading'>Causes :- </h5>
                            <ul className='treatment-ortho-ul'><li>Any type of injury to the spine may leads to compression of the sciatica nerve at its roots.</li>
                                <li> Any infection/toxic material near the area of the nerve.</li>
                                <li> Ruptured disc. </li>
                                <li> Osteoarthritis of the lower spine.</li>
                                <li> Improper sitting postures, over exertion, jerking movements during travelling and sports.</li>
                                <li> Mental stress creates undue pressure on the spinal column and plays an important role in producing low back ache and sciatica.</li>
                                <li> The real cause of sciatica, however, is the direct outcome of excess acidity of the blood and tissues and a rundown condition of the system generally. It is one of the many forms of “acidosis”.</li>
                                <li> Other causes include pregnancy, tumors of the pelvis, deformities of the lower spine and exposure to cold and damp.</li>
                            </ul>
                            <h5 className='app-heading'>Symptoms :-</h5>
                            <ul className='treatment-ortho-ul'><li>Sciatica is a disease having symptoms such as pain, numbness, tingling and weakness starting from the low back and or buttocks and radiating towards the foot through the back of the thigh, knee joint and leg when one or more of the nerve roots forming the sciatic nerve or sciatic nerve alone is either compressed or irritated.</li>
                                <li>Day by day pain increases, reduces the blood circulation, and begins with numbness of the leg and resulting in thinning of the leg.</li></ul>
                            <h5 class=" app-heading">Treatments :-</h5>
                            <p className='para-text'>In sciatica, there is no permanent solution to the people for the pain relief with internal medications, injections and surgeries. In all aspects from the initial stage to the acute phase of this disease, by focusing on age and gender and observing the physical and structural changes in the body, our Kalpataru is providing the ayurveda panchakarma treatment to the entire body, especially for the sciatica from the lumbar region to the foot. It includes </p>
                            <ul className='treatment-ortho-ul'><li>Ayurveda panchakarma chikitsa and its upakramas (snehana and Swedana, Nasya chikitsa, Kati vasti, Anuvasana and Niruha Basti, sarvanga pariseka with kashaya and taila, Upanaha, pralepa and pradeha).  </li>
                                <li>Dietary Regimen To Maintain The Lubrication In Dhatus And To Increase The Immunity.</li>
                                <li>Ayurvedic internal medicine</li>
                                <li>Panchakarma</li>
                                <li>Upakarmas of panchakarma</li>
                                <li>Ahara niyama (dietary regimen)</li>
                                <li>Vyayama (yogic exercise)</li>
                                <li>Chairopractic</li>
                                <li>Marma Therpay </li>
                                <li>Massage Therapy </li>
                                <li>Consoling </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="ortho-left-side">
                                    <h4>Make An appointment </h4>
                                    <ul>
                                        <li> Monday-friday <span> : 9.00-17.00</span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9.00-16.00</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : closed</span></li>
                                        <b> <li>Nedd Urgent Help ? <span>9876543210</span></li></b>
                                    </ul>
                                    <div class="appointment-btn pt-10">
                                        <span></span>
                                        <a href="#" class="main-btn">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6 pt-3">
                                <div class="ortho-card-secondpart ">

                                    <div class="ortho-image-part ">
                                        <div class="image">
                                            <img src="/images/Sciaticapain/sciatica1.png" style={{"height":"180px"}} alt="Image" />
                                        </div>
                                        <div class="cont">
                                            <h4>SCIATICA PAIN </h4>
                                        </div>
                                    </div><br />
                                    <div class="ortho-image-part ">
                                        <div class="image">
                                        <img src="/images/Sciaticapain/sciattica2.jpg" style={{"height":"170px"}} alt="Image" />
                                        </div>
                                        <div class="cont">
                                            <h4>SCIATICA PAIN  </h4>
                                        </div>
                                    </div><br />
                                    <div class="ortho-image-part ">
                                        <div class="image">
                                        <img src="/images/Sciaticapain/sciatica3.jpg" style={{"height":"170px"}} alt="Image" />
                                        </div>
                                        <div class="cont">
                                            <h4>SCIATICA PAIN  </h4>
                                        </div>
                                    </div><br />
                                    <div class="ortho-image-part ">
                                        <div class="image">
                                        <img src="/images/Sciaticapain/sciatica4.jpg" style={{"height":"170px",width:"300px"}} alt="Image" />
                                        </div>
                                        <div class="cont">
                                            <h4>SCIATICA PAIN  </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </div>


    )
}
