import React from 'react'
import { Col,Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className="container-fluid footer-container " >
            <Container  className="footer-container-one" >
                <Row xl={12} >
                    <Col xl={3} lg={3} md={4} sm={5} xs={5} >
                        <img className="footer-image" src="/images/logo/logo.png" />
                    </Col>
                    <Col xl={3} lg={3} md={4} sm={7} xs={7} >
                        <ul className="footer-link" >
                            <h3 className="footer-quick-link" >Quick Links</h3>
                            <li className="footer-link-a"> <Link to="/about">About Kalpataru </Link> </li>
                            <li className="footer-link-a"><Link to="/ourservices"> Our Services </Link> </li>
                            <li className="footer-link-a"><Link to="/gallery"> Gallery </Link> </li>
                            <li className="footer-link-a"><Link to="/carrers"> Careers </Link> </li>
                            <li className="footer-link-a"><Link to="/kalpataru/accomodation"> Facilities </Link> </li>
                        </ul>
                    </Col>
                    <Col className="footer-col-3" xl={3} lg={3} md={4} sm={7} xs={7} >
                        <ul className="footer-link">
                            <h3 className="footer-quick-link">Contact Us</h3>
                            <li> Kalpataru Ayurveda, </li>
                            <li>15 -143 ,New Mirjalaguda, </li>
                            <li> Malkajgiri,Hyderabad,500047 </li>
                            <li>Phone: +919848032919 </li>
                            <li>Email: info@kalpataru.com </li>
                        </ul>
                    </Col>
                    <Col className="footer-col-4" xl={3} lg={3} md={4} sm={5} xs={5} >
                        <h3 className="footer-quick-link">Follow Us</h3>
                        <div className="footer-icon-div" >
                            <a href="https://www.facebook.com/KALPATARUAYURVEDAHOSPITALS " target="_blank" rel="noopener noreferrer"> <img className="footer-icon" src="/images/icons/facebook.png" /> </a>
                            <a href="https://www.instagram.com/kalpataruayurvedaindia/" target="_blank" rel="noopener noreferrer"> <img className="footer-icon" src="/images/icons/instagram.png" /> </a>
                            <a href="https://www.youtube.com/channel/UCTxNbf9RRkeVyfG0y6A43sQ" target="_blank" rel="noopener noreferrer"> <img className="footer-icon" src="/images/icons/youtube.png" /> </a>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center" >
                    <span className='footer_text'> © All Rights Reserved 2021 </span>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
