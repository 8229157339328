import React from 'react'
import Lottie from "lottie-react"
function Infertility1() {
    return (
        <div className='container' >  
           <div className=' row infertility' >
                <div className='col' >
                   <img className='infertility_img' src="/images/treatment/com.gif" alt='' />
                    <h3 className='infer_text'> Our Page is Under Construction! </h3>
                </div>
            </div>
        </div>
    )
}

export default Infertility1