import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Galleryone from '../Container/GalleryContainer/GalleryOne'

export default function Gallery() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Gallery" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/s1.png" title="Home" content="Gallery" />
            <Galleryone/>
            <Footer />
        </React.Fragment>
    )
}
