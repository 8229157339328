import React from 'react'


export default function Diabets1() {
    return (
       
    <div className="section_heading">
        <h1>Kalpataru <span>Diabets</span> Treatment</h1>
      </div>
      

       
    )
}
