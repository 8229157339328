import React from 'react'

function ShishuSwarnaTwo() {
    return (
        <div className='container' >
            {/* <div className='row mb-5' >
                <div className='col' >
                    <img className='yoga_camp_img' src='/images/events/swarna/swarna-bindu.jpg' alt='Loading..' />
                </div>
            </div> */}

            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        The parents of the children Born up to the age of 21 , New Married Couple are welcome to utilize this opportunity to get the advantage from the Samskaras (rituals) invented by the ancient Rishis and made available in the scriptures for the generations to follow. We are only following the path shown by them.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        India has lost its rich cultural heritage, founded by Ancient Rishis, taught the eternal truths of Hindu philosophy and left a record of them for the benefit of posterity, due to several invasions and governance by outsiders since last 500 years, Modern education and changing social values. Only the most orthodox families take the trouble of performing all of them strictly in accordance with the scriptures.India has lost its rich cultural heritage, founded by Ancient Rishis, taught the eternal truths of Hindu philosophy and left a record of them for the benefit of posterity, due to several invasions and governance by outsiders since last 500 years, Modern education and changing social values. Only the most orthodox families take the trouble of performing all of them strictly in accordance with the scriptures.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p' >
                    <p>
                        Kriya yoga samsthan, to create awareness among present generation, about our great ancient cultural heritage and its importance, is conducting Shishu Samskara Maha Yagnam. The Upanishads mention SAMSKARAS as a means to grow and prosper in all four aspects of human pursuit, Dharma, Artha, Kama and Moksha, which are proven ethically and scientifically to be of greater importance to all human beings. The word literally means "putting together, making perfect, getting ready, to prepare", or "a sacred or sanctifying ceremony" in ancient Sanskrit.
                    </p>
                </div>
            </div>

            <div className='row' >
                <h3 className='about-two-title'> BENEFITS OF PARTICIPATING IN THE PROGRAM: </h3>
                <div className='col about-two-ul' >
                    <ul>
                        <li>All the ingredients offered in the Yajna are powerful herbs and vanaspati which after burning in the flames of the Yajna get in to the atmosphere around and enter the physical bodies of the participants through the nose to energize the Ten Pranas and vital physical organs. </li>
                        <li> It will be a wonderful experience one gets along with the entire family members besides a heart-felt satisfaction to remember for long. </li>
                        <li> The participants are expected only to take part with a calm mind with their 100% participation in the SHISHU SAMSKARA MAHAYAJNA so that they will get their physical bodies and mental faculties fully recharged.</li>
                    </ul>

                </div>
                <div className='mb-3 summer_camp_link'>
                    EVERY MONTH “PUSHAYMI NAKSHATRA DAY” - ONLY ONLINE BOOKING.
                </div>
            </div>
        </div>
    )
}

export default ShishuSwarnaTwo