import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Paralysis2() {
    return (
        <div>
            <div className="container homeabout-main pt-5">
                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> <b>Maximum of results are possible with our ayurvedic treatment for those who come immediately after the emergency care. 90% of results, if treated within 3 months of attack of paralysis, 80% results within 1 year.</b></p>
                        <p> ➤ Pakshaghata is one of the most serious disease, which ranks 3rd in the world.  In world, every month approximately 16 lakhs people are paralyzed and in every minute, due to stroke some are paralyzed, and some dies or lives.</p>
                        <p> ➤ In a year, at least 2 million people around the world are attacked by paralysis, of these minimum 70-80% of patients have been treated, it is noteworthy 20% cases are being untreatable. Usually paralysis occurs in those over the age of 50 years with no difference in females and males. Earlier the disease was more common among males, but in modern society women are also suffering from this disease in high numbers.</p>

                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://www.spinalcord.com/hs-fs/hubfs/Updated%20Web%20Page%20Images%202020/Types-of-Paralysis.jpg?width=500&name=Types-of-Paralysis.jpg" height="290px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>



    )
}
