import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function BuyProducts() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Services" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/banner1.jpg" title="Home" content="Our Services" />

            <div className='container'>
                <div className='row ' >
                    <div className="section_heading">
                        <h1>Our  <span> Services </span> </h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">SPINE CARE </h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤	CHRONIC BACK PAIN (LUMBAR SPONDYLOSIS)</li>
                                        <li> ➤	CHRONIC NECK PAIN (CERVICAL SPONDYLOSIS)</li>
                                        <li> ➤	DEGENERATIVE DISC</li>
                                        <li> ➤	HERNIATED DISC</li>
                                        <li> ➤	SPINAL STENOSIS</li>
                                        <li> ➤	ARTHRITIS</li>
                                        <li> ➤	ANKYLOSING SPONDYLITIS</li>
                                        <li> ➤	OSTEOPOROSIS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">PAIN CARE </h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤	FIBROMYALGIA</li>
                                        <li> ➤	MUSCULOSKELETON PAIN</li>
                                        <li> ➤  CHRONIC MUSCLE PAIN</li>
                                        <li> ➤	BURSITIS</li>
                                        <li> ➤	TENDINITIS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">JOINT CARE</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤	FIBROMYALGIA</li>
                                        <li> ➤	MUSCULOSKELETON PAIN</li>
                                        <li> ➤  CHRONIC MUSCLE PAIN</li>
                                        <li> ➤	BURSITIS</li>
                                        <li> ➤	TENDINITIS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">NEURO CARE</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤ PARALYSIS DUE TO CEREBRO VASCULAR DISEASE INCLUDING STROKE</li>
                                        <li> ➤ PARKINSON’S DISEASE</li>
                                        <li> ➤ MIGRAINE AND OTHER HEADACHE DISORDERS</li>
                                        <li> ➤ ALZHEMIER’S DISEASE</li>
        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">NEURO MUSCULAR DISORDERS</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤	MUSCULAR DYSTROPHY</li>
                                        <li> ➤  MYASTHENIA GRAVIS</li>
                                        <li> ➤  MYOPATHY</li>
                                        <li> ➤	MYOSITIS</li>
                                        <li> ➤	PERIPHERAL NEUROPATHY </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">SPECIAL TREATMENT & CONSULTATION</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤	HEART CARE </li>
                                        <li> ➤	KIDNEY CARE</li>
                                        <li> ➤  CANCER   </li>
                                        <li> ➤	INFERTILITY </li>
                                        <li> ➤	ENT  </li>
                                        <li> ➤	GALL STONES   </li>
                                        <li> ➤	KIDNEY STONES   </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">WOMEN CARE</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤ INFERTILITY </li>
                                        <li> ➤ POLYCYSTIC OVARIAN SYNDROME</li>
                                        <li> ➤ ENDOMETRIOSIS</li>
                                        <li> ➤ UTERINE FIBROIDS</li>
                                        <li> ➤ INTERSTITIAL CYSTITIS</li>
                                        <li> ➤ METRORRHAGIA </li>
                                        <li> ➤ OLIGOMENORRHOEA</li>
                                        <li> ➤	LEUCORRHOEA </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">CHILD  CARE </h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤ CHRONIC INFLAMMATORY DEMYELINATING SYNDROME</li>
                                        <li> ➤  CONGENITAL MYOPATHY </li>
                                        <li> ➤  GUILLAIN BARRE SYNDROME</li>
                                        <li> ➤	MUSCULAR DYSTROPHY </li>
                                        <li> ➤	MYASTHENIA GRAVIS </li>
                                        <li> ➤	JUVELINE ARTHRITIS  </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title"> BEAUTY  CARE</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤ DANDRUFF </li>
                                        <li> ➤ ALOPECIA AREATA</li>
                                        <li> ➤ SKIN CARE (Face Packs For Brightening Of Skin, Skin Pathes, Spots On Face, Dark Circles)   </li>
                                        <li> ➤ HAIR FALL  And GREYING OF HAIR</li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title"> ANORECTAL DISEASES</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤	FISTULA IN ANO (BHAGANDARA) </li>
                                        <li> ➤  FISSURE IN ANO (PARIKARTIKA)</li>
                                        <li> ➤ HAEMORRHOIDS (ARSHA MOOLALU)</li>
                                        <li> ➤ PILO NIDAL SINUS (NADI VRANAMU)</li>
                                        <li> ➤ ANO RECTAL ABSCESS (GUDA VRANAMU)</li>
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title">GENERAL</h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤ DIABETES</li>
                                        <li> ➤ HYPERTENSION </li>
                                        <li> ➤ OBESITY</li>
                                        <li> ➤	CORN  </li>
                                        <li> ➤	LIPOMA  </li>
                                        <li> ➤	CHRONIC NON HEALING ULCERS (DUSHTA VRANAMU)</li>
                                        <li> ➤	PEPTIC AND DUODENAL ULCER </li>
                                        <li> ➤	CONSTIPATION </li>
                                      {/*   <li> ➤	IBS  </li>
                                        <li> ➤	GIT   </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <div class="service-card">
                            <div class="card">
                                <div class="services-head">
                                    <h4 class="services-title"> TREATEMENT METHODS </h4>
                                </div>
                                <div class="services-body">
                                    <ul>
                                        <li> ➤ Ayurvedic internal medicine </li>
                                        <li> ➤ Panchakarma</li>
                                        <li> ➤ Upakarmas of panchakarma  </li>
                                        <li> ➤ Ahara niyama (dietary regimen)</li>
                                        <li> ➤ Vyayama (yogic exercise)</li>
                                        <li> ➤ Chairopractic</li>
                                        <li> ➤	Marma Therpay </li>
                                        <li> ➤	Massage Therapy </li>
                                        <li> ➤	Consoling </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}
