import React, { useMemo } from 'react'
import Lottie from "lottie-react"
/* import success from '../../../assets/images/lottie/success.gif'; */
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function PaymentSuccess() {

    const history = useHistory();

    useMemo(() => {
        setTimeout(() => {
            history.push("/");
        }, 5000)
    })

    return (
        <div className='container' >
            <div className='row success_page' >
                <div className='col  ' >
                    {/* <Lottie options={defaultOptions} height={400} width={400}  /> */}
                    <img className='lottie_img' src="images/sucess/success.gif" alt='' />
                    <h3 className='mb-2 success_text'> Thanks For Making Payment ! </h3>
                    <p className='mb-5'> You will recieve your entry passs soon ! </p>
                    <Link to="/" className="success_link" >Go Back</Link>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess