import React from 'react'
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import images from "../../../Data/Gallery/GalleryData.json";


function GalleryOne() {
    return (
        <div className='container-fluid'>
                <div className="section_heading">
                <h1>Top <span> Image </span> Gallery</h1>
            </div>
            < div className='container-fluid p-5 img_container mb-5'>
                <div className='row' >
                    <div className='col'>
                        <ImageGallery
                            items={images}
                            slideNumber={true}
                             width="850px"
                             height="500px"
                             slideBackgroundColor="darkgrey"
                             slideImageFit="cover"
                             time={2000}
                            // defaultImage={defaultImage}
                            showBullets={false}
                            showIndex={true}
                            showThumbnails={true}
                            lazyLoad={true}
                            showPlayButton={true}
                            infinite={true}
                            thumbnailPosition='bottom'     
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryOne