import React from 'react'


export default function Paralysis1() {
    return (
       
   
      <div className='section_heading'>
        <h1>Kalpataru <span>Paralysis</span> Treatment</h1>
        </div>
    

       
    )
}
