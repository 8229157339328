import React from 'react'

function FranchiseTwo() {
    return (
        <div className='container-fluid'>
            <div className='row' >
                <div className='col franchise_title mb-3 mt-3' > Join Us to become a Franchise! </div>
            </div>
            <div className='row'>
                <div className=' col mb-3 mt-2 franchise_title_two'>
                    We are looking for franchises in PAN-INDIA
                </div>
            </div>
            <div className='row franch_row'>
                <div className='col summer_camp_p'>
                    <ul className='summer_camp_list'>
                        <li>   <b> Category : </b> <span> Ayurveda Clinics / Nature Cure Clinics / Hospitals </span> </li>
                        <li>   <b> Sector : </b> <span> Hospitals, Clinics, and Medical stores </span> </li>
                        <li>   <b> Service Area : </b> <span> Spine And Neuro Care –Surgery Stage </span> </li>
                        <li>   <b> Concept : </b> <span> Ayurveda Spine Care & Neuro Care </span> </li>
                        <li>   <b> Agreement Period : </b> <span> The Agreement tenure is 5 years. </span> </li>
                        <li>   <b> Total No. of Units : </b> <span> 100 Units </span></li>
                        <li>   <b> Total No. of Units we have established till now : </b> <span> 20 Units </span> </li>
                        <li>   <b> Support Offered from our end : </b> <span> We provide training and Marketing Support to our Franchisees. </span> </li>
                        <li>   <b> Investment required : </b> <span> 30 Lac – 50 Lac </span> </li>
                        <li>   <b> Franchise Only for : </b> <span> Pure Vegetarians </span> </li>
                        <li>   <b> Business Established : </b> <span> 1999 </span> </li>
                        <li>   <b> Franchising Commenced : </b> <span>2011 </span> </li>
                        <li className='mt-2'> <b> ( We anticipate Franchisees who have good business skills, and management skills and are service-oriented ) </b> </li>
                    </ul>
                </div>
            </div>

            <div className='row mt-3' >
                <div className='col franchise_title mb-3 mt-3' > Collaborate with Us! </div>
            </div>
            <div className='row'>
                <div className=' col mb-3 mt-2 franchise_title_two'>
                    We are looking for PAN-INDIA Collaborations
                </div>
            </div>

            <div className='row franch_row mb-5' >
                <div className='col summer_camp_p'>
                    <ul className='summer_camp_list'>
                        <li>   <b> Category : </b>  <span> All India Ayurveda and Nature Cure Hospitals </span>  </li>
                        <li>   <b> Service Area : </b>  <span>  Ayurveda Spine And Neuro care  </span></li>
                        <li>   <b> Investment : </b>  <span> Nill </span> </li>
                        <li>   <b> Requirement : </b>  <span> Well Established Hospital Or Clinics </span> </li>
                        <li>   <b> Hospital Size : </b>  <span> We require at least a well-equipped 10-Bedded facility for Inpatients </span> </li>
                        <li>   <b> Collaboration Agreement : </b>  <span> At least 3-5 Years  (FREE /PAID By Terms and conditions)  </span></li>
                        <li>   <b>  Publicity : </b>   <span>Electronic, Print, Google, Social Media, and local Publicity </span> </li>
                        <li>   <b> Income Sharing :</b>   <span> According to Agreement's Terms and conditions </span> </li>
                        <li>   <b> Support: </b>  <span> We will offer 100% support with Training  </span></li>
                        <li>   <b> Quality : </b>  <span>  We do not compromise on quality </span> </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default FranchiseTwo