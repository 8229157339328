import React from 'react'

function ShishuSwarnaOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>SHISHU <span> SAMSKARA </span> MAHA YAGNA</h1>
            </div>
            <div className='row mb-5' >
                <div className='col-xl-6 summer_camp_p' >
                    <p>
                        Yajna is an age old system of Hindu ritual traditionally performed in accordance with the way prescribed by the ancient Rishi’s to get a predetermined result. It is our long belief that the participants in a yajna are blessed by the invoked deities and also the lineage of the Rishi’s /Gurus whose presence in all auspicious programs are felt.
                    </p>
                    <p>
                        The Maharshi Dayananda Saraswati Kriya Yoga Samsthan, Hyderabad founded in 1999 to conduct programs according to the Vedic System , made a program to conduct Shishu Samskara MahaYajna on every Pushya Nakshatra Day. Just like the ritual/function of Anna Prashanam is done to start food intake of a child to make the physical body system to adjust to getting the required energies through ‘Anna’ (food), the Shishu Samskara MahaYagna is aimed at getting immunity to the child’s physical body and also to get Jnana (knowledge) to ripe into an intellectual person. It is a time tested Ayurvedic method.
                    </p>
                </div>
                <div className='col-xl-6' >
                    <img className='yoga_camp_img' src='/images/events/swarna/swarna.png' />
                </div>
            </div>
        </div>
    )
}

export default ShishuSwarnaOne