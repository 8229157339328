import React from 'react'
import Expo from '../../Container/EventsContainer/Expo'
import Breadcrumb from '../../BreadCrumb/BreadCrumb'
import Footer from '../../Container/Footer/Footer'
import SEO from '../../Container/SEO/SEO'
import TopNav from '../../Container/TopNav/TopNav'
import ExpoOne from '../../Container/EventsContainer/ExpoOne'
import ExpoTwo from '../../Container/EventsContainer/ExpoTwo'

export default function AyurvedaExpo() {
    return (
       <React.Fragment>
        <SEO title="Kalpataru || Ayurveda Expo"  />
        <TopNav/>
        <Breadcrumb image="/images/breadcrumb/organic.jpg" title="Home" content="Ayurveda Expo"/>
        <Expo/>
        <ExpoOne/>
        <ExpoTwo/>
        <Footer/>
       </React.Fragment>
    )
}
