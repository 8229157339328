import React from 'react'
import Breadcrumb from '../../BreadCrumb/BreadCrumb'
import SwarnaBinduOne from '../../Container/EventsContainer/SwarnaBinduOne'
import SwarnaBinduTwo from '../../Container/EventsContainer/SwarnaBinduTwo'
import Footer from '../../Container/Footer/Footer'
import SEO from '../../Container/SEO/SEO'
import TopNav from '../../Container/TopNav/TopNav'

function SwarnaBindu() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Swarna Bindu" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/banner04.jpg" title="Home" content="Swarna Bindu" />
            <SwarnaBinduOne />
            <SwarnaBinduTwo />
            <Footer />
        </React.Fragment>
    )
}

export default SwarnaBindu