import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Paralysis3() {
    return (



        <section className="Kalpataru-second-ortho pt-5">

            <div className="container-fluid">

                <div className="row mt-30">
                    <div className="col-md-3 col-sm-6">
                        <a href=""> <div className="ortho-card">
                        <img src="/images/paralysis/Paralysis1.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Paralysis</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/paralysis/Paralysis2.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Paralysis</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/paralysis/Paralysis3.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Paralysis</p>
                            </div>

                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/paralysis/Paralysis4.jpg" style={{"height":"180px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Paralysis</p>
                            </div>

                        </div></a>
                    </div>
                </div>
            </div>

        </section>





    )
}
