import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Infertility1 from '../Container/Infertilitytreatment/Infertility1'



export default function CancerCare () {
    return (
       
      <React.Fragment>
      <SEO title="Kalpataru || CancerCare"  />
      <TopNav/>
      <Breadcrumb image="/images/breadcrumb/coming.jpg" title="Home" content="CancerCare"/>
       <Infertility1/>
      <Footer/>
     </React.Fragment>

       
    )
}
