import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import About from '../Container/About/About'
import AboutOne from '../Container/About/AboutOne'
import AboutTwo from '../Container/About/AboutTwo'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function AboutKalpataru() {
    return (
       <React.Fragment>
        <SEO title="Kalpataru || About"  />
        <TopNav/>
        <Breadcrumb image="/images/breadcrumb/banner5.jpg" title="Home" content="About Kalpataru"/>
        <About/>
        <AboutOne/>
        <AboutTwo/>
        <Footer/>
       </React.Fragment>
    )
}
