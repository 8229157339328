import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import CoursesOne from '../Container/Courses/CoursesOne'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function Courses() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Admissions" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/herb.jpg" title="Home" content="Courses" />
            <CoursesOne />
            <Footer />
        </React.Fragment>
    )
}
