import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Paralysis1 from '../Container/Paralysistreatment/Paralysis1'
import Paralysis2 from '../Container/Paralysistreatment/Paralysis2'
import Paralysis3 from '../Container/Paralysistreatment/Paralysis3'
import Paralysis4 from '../Container/Paralysistreatment/Paralysis4'



export default function Paralysis() {
    return (

        <React.Fragment>
            <SEO title="Kalpataru ||Paralysis" />
            <TopNav />
            <Breadcrumb image="/images/breadcrumb/paralysis.jpg"  title="Home" content="Paralysis" />
            <Paralysis1 />
            <Paralysis2 />
            <Paralysis3 />
            <Paralysis4 />
            <Footer />
        </React.Fragment>


    )
}
