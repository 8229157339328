import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Neck2() {
    return (
        <div>
            <div className="container homeabout-main pt-5">
                <div className="row justify-content-between">

                    <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                        <p> <b>Neck Pain Due To Muscle Strain Or Pinched In Between Spine Vertebral Discs. Get Effective, Treatment With Our Kalpataru Ayurveda</b></p>
                        <p> ➤ Your neck is flexible & supports the weight of your head, so it can be vulnerable to injuries and conditions that cause neck pain & restrict motion.</p>
                        <p> ➤ Cervical spondylosis is a degenerative disease of cervical region of the spine. It results from degenerative changes in the intervertebral discs lead to secondary change in the adjacent vertebrae. These changes in the cervical spine may affect one or more nerve roots, the cervical cord at one or more levels or may cause simultaneous damage to the nerve roots and cord. It is an arthritic process involving the vertebrae and is often associated with osteoarthritis in the rest of the skeleton.</p>
                        <p> ➤ The spine is a vital part of the back, a complex structure of muscles, bone and elastic tissue, which is known as life bone of the body. The core of our spine is composed of 24 bones, piled one on top of the other.</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <img className="homeabout-image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP7PThb74NpNHj67-PmAsVmmKR08kemhyEPA&usqp=CAU" height="250px" alt="Loading.." />
                    </div>

                </div>
            </div>
        </div>



    )
}
