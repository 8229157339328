import React, { useEffect } from 'react'

function PaymentButton() {
    useEffect(() => {
        async function makeForm() {
            const Script = document.createElement("script");
            const Form = document.getElementById('donateForm');
            Script.setAttribute('src', 'https://checkout.razorpay.com/v1/payment-button.js')
            Script.setAttribute('data-payment_button_id', 'pl_JCT4CahJhmgSkr')
            Script.setAttribute('data-button_theme', 'razerpay-dark')
            Form.appendChild(Script);
        }
        makeForm()
    }, [])
    
    return (
        <div className='container'>
            <div className='' >
                <div className='text-center mt-3' >
                    <form id='donateForm'> </form>
                </div>
            </div>
        </div>
    )
}

export default PaymentButton