import React from 'react'


export default function Diabets2() {
  return (

    /*     <div className='container'>
        <div className='row'>
          <div className='col-sm-4 pt-3 '>
            <div className='card box-shadow'>
              <div className='obesity'>
              <img src='https://media.istockphoto.com/photos/weight-loss-success-picture-id153048232?k=20&m=153048232&s=612x612&w=0&h=B2fSduT5KGZ9m4VAKq24Ky4TwdVL4W61i8fEM1xe-K8=' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Over weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
          <div className='col-sm-4 pt-3'>
            <div className='card box-shadow'>
               <div className='obesity'>
              <img src='https://thumbs.dreamstime.com/b/fat-women-suffer-obesity-big-hamburger-apple-hands-junk-food-concept-woman-gray-background-73918587.jpg' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Under weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
          <div className='col-sm-4 pt-3'>
            <div className='card box-shadow'>
               <div className='obesity'>
              <img src='https://media.istockphoto.com/photos/weight-loss-success-picture-id153048232?k=20&m=153048232&s=612x612&w=0&h=B2fSduT5KGZ9m4VAKq24Ky4TwdVL4W61i8fEM1xe-K8=' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Over weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
        </div>
       
      </div> */
    <div>
      <div className="container homeabout-main pt-5">
        <div className="row justify-content-between">

          <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
            <p> <b>Kalpataru Ayurvedic Practices Can Help People To Live Happily Without Suffering That They Have Diabetes Without Any Side Effects.</b></p>
            <p> ➤ The pancreas in our body is an organ that prepares hormones & enzymes for our digestion also called as an organ of the digestive system and endocrine system of vertebrates.</p>
            <p> ➤ In humans, it is located in the abdomen behind the stomach with minimum of 6 inches and functions as a gland. </p>
            <p> ➤ The pancreas has both an endocrine and exocrine function. As an endocrine gland, it functions mostly to regulate blood sugar levels, secreting the hormones insulin, glucagon, somatostatin and the pancreatic polypeptide.	</p>
            <p> ➤ Pancreas glands help us to breakdown fat, carbohydrates and proteins in our bodies. </p>
            <p> ➤ The kloma grandhi is called pancreas in English language, in greek; it means the softest organ. </p>
            <p> ➤ Diabetes mellitus in India, minimum 7 crores people suffer from this disease. Our country is also included in diabetes list after the US, CHINA in the world. 	</p>
            <p> ➤ We celebrate world diabetes day an November 14th every year. </p>
            <p> ➤ There is no known cure for diabetes in the world and no other option than to keep yourself in control of diabetes and take appropriate care for the rest of your life.</p>
            <p> ➤	Diabetes is of 3 types; type 1 diabetes, type 2 Diabetes and gestational diabetes (diabetes during pregnancy).</p>
            <p> ➤ Main cause for type-1 diabetes, beta cells in the pancreas does not produce enough insulin is to control the level of glucose in the body. </p>
            <p> ➤ Today individuals with diabetes must take insulin and tablets, if not taking tablets in right time, may lead to serious consequences.</p>

          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <img className="homeabout-image" src="https://www.mdanderson.org/images/publications/cancerwise/D/CW-diabetes-iStock-1168613048.jpg"  style={{"height":"450px","width":"550px"}} alt="Loading.." />
          </div>

        </div>
      </div>
    </div>


  )
}
