import React, { useEffect, useState } from 'react'
import RegisterValidation from './RegisterValidation'; 
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import PaymentButton from '../../Pages/PaymentButton';

function RegisterForm() {
    const history = useHistory();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        fullName: "",
        email: "",
        gender: "",
        fatherName: "",
        motherName: "",
        gaurdianName: "",
        dateOfBirth: "",
        age: "",
        classStudy: "",
        motherTongue: "",
        motherOcupation: "",
        fatherOccupation: "",
        interest: "",
        extraActivities: "",
        otherCampBefore: "",
        mobile: "",
        mobileOne: "",
        camps: "",
        address: "",
        state: "",
        city: "",
        pincode: "",
        nationality: "",
        complaint: "",
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value, });
        setErrors(RegisterValidation(values));
    };

    const handleSubmit = () => {

        let formData = new FormData();

        formData.append("FullName", values.fullName);
        formData.append("Email", values.email);
        formData.append("Gender", values.gender);
        formData.append("Father Name", values.fatherName);
        formData.append("Mother Name", values.motherName);
        formData.append("Gaurdian Name", values.gaurdianName);
        formData.append("Date Of Birth", values.dateOfBirth);
        formData.append("Age", values.age);
        formData.append("Class Studied", values.classStudy);
        formData.append("Mother Tongue", values.motherTongue);
        formData.append("Mother Occupation", values.motherOcupation);
        formData.append("Father Occupation", values.fatherOccupation);
        formData.append(" Interest", values.interest);
        formData.append("Extra Activities", values.extraActivities);
        formData.append("Participated in Other Camp Before", values.otherCampBefore);
        formData.append("Mobile", values.mobile);
        formData.append("Alternate Mobile", values.mobileOne);
        formData.append("Camps", values.camps);
        formData.append("Address", values.address);
        formData.append("City", values.city);
        formData.append("State", values.state);
        formData.append("Pincode", values.pincode);
        formData.append("nationality",values.nationality);
        formData.append("complaint",values.complaint);

        setLoading(true);
        const api = "https://www.actionforms.io/e/r/summercampregister";

        Axios.post(api, formData)
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    setLoading(false);
                    history.push("/success");
                } else {
                    console.log(response)
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log("err", err)
                setLoading(false);
            })
    };


    useEffect(() => {
        setErrors(RegisterValidation(values));
    }, [])

    const register = (e) => {
        e.preventDefault();
        handleSubmit();
    }

    return (
        <div className='container mb-5 mt-4' >
            <div className='row mb-4' >
              {/*   <div className='col prog_title_col' >
                    <h6> Fill The Form Before Making Payment </h6>
                    <h3> Registration Form </h3>
                </div> */}
                  <div className="section_heading">
                     <h5 className='hecolor'> Fill The Form Before Making Payment </h5>
                     <h1>Registration <span> Form </span> </h1>
              {/*   <h1> Registration </h1> */}
            </div>
            </div>
            <div className='row' >
                <div className='col-xl-8 register_col_form' >
                    <form className="row g-3 " onSubmit={register}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="fullName" className="form-label">Full Name</label>
                            <input type="text"
                                className="form-control"
                                id="fullName"
                                placeholder="Enter Full Name"
                                name="fullName"
                                value={values.fullName}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.name ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.name} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter Your Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                autoComplete="off"
                                required
                            />
                            {
                                errors.email ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.email} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="gender" className="form-label">Gender</label>
                            <select id="gender" className="form-select" name="gender" value={values.gender} onChange={handleChange} required>
                                <option selected>Choose...</option>
                                <option>Male</option>
                                <option>Female</option>
                            </select>
                            {
                                errors.gender ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.gender} </div> : null
                            }
                        </div>
                       {/*  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="fatherName" className="form-label">Father Name</label>
                            <input type="text"
                                className="form-control"
                                id="fatherName"
                                placeholder="Enter Father Name"
                                name="fatherName"
                                value={values.fatherName}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.fatherName ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }}> {errors.fatherName} </div> : null
                            }
                        </div> */}
                      {/*   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="motherName" className="form-label">Mother Name</label>
                            <input type="text"
                                className="form-control"
                                id="motherName"
                                placeholder="Enter Mother Name"
                                name="motherName"
                                value={values.motherName}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.motherName ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.motherName} </div> : null
                            }
                        </div> */}
                      {/*   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="gaurdianName" className="form-label">Gaurdian Name</label>
                            <input type="text"
                                className="form-control"
                                id="gaurdianName"
                                placeholder="Enter Gaurdian Name"
                                name="gaurdianName"
                                value={values.gaurdianName}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.gaurdianName ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.gaurdianName} </div> : null
                            }
                        </div> */}
                      {/*   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="dateOfBirth" className="form-label">Date of Birth</label>
                            <input type="date"
                                className="form-control"
                                id="dateOfBirth"
                                placeholder="Select Date Of Birth"
                                name="dateOfBirth"
                                value={values.dateOfBirth}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.dateOfBirth ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.dateOfBirth} </div> : null
                            }
                        </div> */}
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="age" className="form-label">Age</label>
                            <input type="text"
                                className="form-control"
                                id="age"
                                placeholder="Enter Patient Age"
                                name="age"
                                value={values.age}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.age ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.age} </div> : null
                            }
                        </div>

                       {/*  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="classStudy" className="form-label">Class Studying</label>
                            <input type="text"
                                className="form-control"
                                id="classStudy"
                                placeholder="Enter Student Class of Study "
                                name="classStudy"
                                value={values.classStudy}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.classStudy ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.classStudy} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="motherTongue" className="form-label">Mother Tongue</label>
                            <input type="text"
                                className="form-control"
                                id="motherTongue"
                                placeholder="Enter Student Mother Tongue"
                                name="motherTongue"
                                value={values.motherTongue}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.motherTongue ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.motherTongue} </div> : null
                            }
                        </div> */}
                       {/*  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="motherOcupation" className="form-label">Mother Occupation</label>
                            <input type="text"
                                className="form-control"
                                id="motherOcupation"
                                placeholder="Enter Mother Occupation"
                                name="motherOcupation"
                                value={values.motherOcupation}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.motherOcupation ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.motherOcupation} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="fatherOccupation" className="form-label">Father Occupation</label>
                            <input type="text"
                                className="form-control"
                                id="fatherOccupation"
                                placeholder="Enter Father Occupation"
                                name="fatherOccupation"
                                value={values.fatherOccupation}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.fatherOccupation ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.fatherOccupation} </div> : null
                            }
                        </div> */}
                        {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="interest" className="form-label">Participant Field Of Interest</label>
                            <input type="text"
                                className="form-control"
                                id="interest"
                                placeholder="Enter Student Interest"
                                name="interest"
                                value={values.interest}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.interest ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.interest} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="extraActivities" className="form-label">Extra Curricular Activities</label>
                            <input type="text"
                                className="form-control"
                                id="extraActivities"
                                placeholder="Enter Student Extra Activities"
                                name="extraActivities"
                                value={values.extraActivities}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.extraActivities ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.extraActivities} </div> : null
                            }
                        </div> */}
                      {/*   <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="otherCampBefore" className="form-label">Did You Attended Any Camp Before</label>
                            <select id="otherCampBefore" className="form-select" name="otherCampBefore" value={values.otherCampBefore} onChange={handleChange} required>
                                <option selected>Choose...</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                            {
                                errors.otherCampBefore ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.otherCampBefore} </div> : null
                            }
                        </div> */}
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="mobile" className="form-label">Phone Number</label>
                            <input type="number"
                                className="form-control"
                                id="mobile"
                                placeholder='Enter Phone Number'
                                name="mobile"
                                value={values.mobile}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.mobile ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.mobile} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="mobileOne" className="form-label">Emergency Phone Number</label>
                            <input type="number"
                                className="form-control"
                                id="mobileOne"
                                placeholder='Enter Emergency Phone Number'
                                name="mobileOne"
                                value={values.mobileOne}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.mobileOne ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.mobileOne} </div> : null
                            }
                        </div>
                    {/*     <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="camps" className="form-label">Camps</label>
                            <select id="camps" className="form-select" name="camps" value={values.camps} onChange={handleChange} required>
                                <option selected>Choose...</option>
                                <option>Residential Summer Camp on 1st May</option>
                                <option>North India Adventure Camp</option>
                                <option>South India Adventure Camp</option>
                            </select>
                            {
                                errors.camps ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.camps} </div> : null
                            }
                        </div> */}
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="address" className="form-label">Address</label>
                            <input type="text"
                                className="form-control"
                                id="address"
                                placeholder="Enter Your Address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.address ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.address} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="city" className="form-label">City </label>
                            <input type="text"
                                className="form-control"
                                id="city"
                                placeholder='Enter Your City'
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.city ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.city} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="state" className="form-label">State</label>
                            <input type="text"
                                className="form-control"
                                id="state"
                                placeholder='Enter Your State'
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.state ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.state} </div> : null
                            }
                        </div>
                       {/*  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="pincode" className="form-label">Nationality</label>
                            <input type="text"
                                className="form-control"
                                id="pincode"
                                placeholder='Enter Pincode'
                                name="pincode"
                                value={values.pincode}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.pincode ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.pincode} </div> : null
                            }
                        </div> */}
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="nationality" className="form-label">Nationality</label>
                            <select id="nationality" className="form-select" name="nationality" value={values.nationality} onChange={handleChange} required>
                                <option selected>Choose...</option>
                                <option>Indian</option>
                                <option>Hong Konger</option>
                            </select>
                            {
                                errors.age ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.age} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="complaint" className="form-label">EnterComplaint</label>
                            <input type="text"
                                className="form-control"
                                id="complaint"
                                placeholder="Enter complaint"
                                name="complaint"
                                value={values.complaint}
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.age ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.age} </div> : null
                            }
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <label for="complaint" className="form-label">Enter Comment </label>
                            <input type="text"
                                className="form-control"
                                id="comment "
                                placeholder="Enter comment "
                                name="comment "
                                value={values.comment }
                                onChange={handleChange}
                                required
                            />
                            {
                                errors.age ? <div style={{ color: 'red', marginTop: '5px', marginLeft: '5px', fontSize: '14px' }} > {errors.age} </div> : null
                            }
                        </div>
                        <div className='row' >
                            <div className="col register_form_col_btn ">
                                <button type="submit" className=" register_form_btn">
                                    {
                                        loading
                                            ?
                                            <div className="spinner-border spinner-border-sm text-light" style={{ marginRight: '5px' }} role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                            :
                                            " "
                                    }
                                    Submit Application
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='col-xl-4 ' >
                    <div className='register_col_form_one mt-3 mb-3' >
                        <h3 className='hecolor'> Make Online Payment </h3>
                        <PaymentButton />
                    </div>
                    <div className='register_col_form_one' >
                        <h3 className='hecolor'> Make Bank Transfer  </h3>
                        <h4> <b> Bank Name : </b> State Bank of India </h4>
                        <h4> <b> Name : </b>  KRIYA YOGA SAMSTHAN  </h4>
                        <h4> <b>  Account Type : </b>  Current Account  </h4>
                        <h4> <b> Account No : </b>  3454 820 3370  </h4>
                        <h4> <b> IFSC Code : </b> SBIN0003608 </h4>
                        <h4> <b> Branch : </b> VIDYA NAGAR HYD  </h4>
                        <h4> <b> Helpline No : </b> Kriya yoga Samstan Account DEPT <b>+919392033555</b>  </h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterForm