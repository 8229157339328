import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import Home from "./Components/Pages/Home";

// CSS
import "./assets/css/Style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/swiper-bundle.min.css'
import AboutKalpataru from "./Components/Pages/AboutKalpataru";
import AboutKalpataruHospitals from "./Components/Pages/AboutKalpataruHospitals";
import AboutKalpataruStores from "./Components/Pages/AboutKalpataruStores";
import MedicalTeam from "./Components/Pages/MedicalTeam";
import Accomodation from "./Components/Pages/Accomodation";
import SciaticaPain from "./Components/Pages/SciaticaPain ";
import BackPain from "./Components/Pages/BackPain";
import NeckPain from "./Components/Pages/NeckPain";
import Paralysis from "./Components/Pages/Paralysis";
import Obesity from "./Components/Pages/Obesity";
import Diabetes from "./Components/Pages/Diabetes";
import Infertility from "./Components/Pages/Infertility";
import HeartCare from "./Components/Pages/HeartCare";
import CancerCare from "./Components/Pages/CancerCare";
import KidneyCare from "./Components/Pages/KidneyCare";
import KneePain from "./Components/Pages/KneePain";
import Pails from "./Components/Pages/Pails";
/* import Womenchildcare from "./Components/Pages/Womenchildcare";
import Beautycare from "./Components/Pages/Beautycare";
import Diagnosis from "./Components/Pages/Diagnosis"; */
import Contact from "./Components/Pages/Contact";
import AyurvedaExpo from "./Components/Pages/Events/AyurvedaExpo";
import YogaCamp from "./Components/Pages/Events/YogaCamp";
import ShishuSwarna from "./Components/Pages/Events/ShishuSwarna";
// import BuyProducts from "./Components/Pages/BuyProducts";
import Register from "./Components/Pages/Register";
import Franchise from "./Components/Pages/Franchise";
import Blogs from "./Components/Pages/Blogs";
import SuccessPage from "./Components/Container/Register/SuccessPage";
import PaymentSuccess from "./Components/Container/Register/PaymentSuccess";
import SwarnaBindu from "./Components/Pages/Events/SwarnaBindu";
import SummerCamp from "./Components/Pages/Events/SummerCamp";
import Career from "./Components/Pages/Career";
import OurServices from "./Components/Pages/OurServices"
import ScrollToTop from "./assets/css/Pages/ScrollToTop";
import Gallery from "./Components/Pages/Gallery";
import Courses from "./Components/Pages/Courses";

function App() {
  const history = useHistory();

  return (
    <div className="App">
      <Router history={history} basename='/' >
        <ScrollToTop>
          <Switch>
            {/* <Route exact path='/login' component={Login} /> */}
            <Route exact path='/' component={Home} />
            <Route path='/about' component={AboutKalpataru} />
            <Route path='/kalpataru/hospitals' component={AboutKalpataruHospitals} />
            <Route path='/kalpataru/stores' component={AboutKalpataruStores} />

            {/* Treatments */}

            {/*  <Route path='/kalataru/medical/team' component={MedicalTeam}/> */}
            <Route path='/kalpataru/medical/team' component={MedicalTeam} />
            <Route path='/kalpataru/accomodation' component={Accomodation} />
            <Route path='/Treatments/SciaticaPain' component={SciaticaPain} />
            <Route path='/Tretments/BackPain' component={BackPain} />
            <Route path='/Treatments/NeckPain' component={NeckPain} />
            <Route path='/Treatments/Paralysis' component={Paralysis} />
            <Route path='/Treatments/Obesity' component={Obesity} />
            <Route path='/Treatments/Diabetes' component={Diabetes} />
            <Route path='/Treatments/Infertility' component={Infertility} />
            <Route path='/Treatments/HeartCare' component={HeartCare} />
            <Route path='/Treatments/CancerCare' component={CancerCare} />
            <Route path='/Treatments/KidneyCare' component={KidneyCare} />
            <Route path='/Treatments/KidneyCare' component={KidneyCare} />
            <Route path='/Treatments/KneePain' component={KneePain} />
            <Route path='/Treatments/Pails' component={Pails} />
         {/*    <Route path='/Treatments/Beautycare' component={Beautycare} />
            <Route path='/Treatments/Diagnosis' component={Diagnosis} /> */}
            <Route path='/contact' component={Contact} />
            {/* Treatments */}

            {/* Events */}
            <Route path='/ayurveda/expo' component={AyurvedaExpo} />
            <Route path='/yogacamp' component={YogaCamp} />
            <Route path='/shishu/swarna' component={ShishuSwarna} />
            <Route path='/summer/camp' component={SummerCamp} />
            <Route path='/swarna/bindu' component={SwarnaBindu} />


            {/*  <Route path='/buy/products' component={BuyProducts} /> */}
            <Route path='/register' component={Register} />
            <Route path='/franchise' component={Franchise} />
            <Route path='/blog' component={Blogs} />
            <Route path='/success' component={SuccessPage} />
            <Route path='/paymentsuccess' component={PaymentSuccess} />
            <Route path='/carrers' component={Career} />
            <Route path='/ourservices' component={OurServices} />
            <Route path='/gallery' component={Gallery} />
            <Route path='/courses' component={Courses} />

            <Redirect from="*" to="/" />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
