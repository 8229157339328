import React from 'react'


export default function Obesity3() {
    return (

        <div>
            <div className="container pt-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className='orthocare-desc'>
                            {/*  <h5 className=" app-heading">About Obesity :-</h5>
  <p> lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
  LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world
  Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
  Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</p> */}
                            <h5 className='app-heading'>Causes :- </h5>
                            <ul className='treatment-ortho-ul'><li> Usually we are overweight due to high dietary supplements and this overweight is the most common in world from all over countries, mostly in high income families and in affluent families also. </li>
                                <li> During pregnancy and after each child birth, a women’s body is usually more likely to gain weight from 10-12kg.</li>
                                <li> Females with menstrual problems are also at high risk for weight gain, overweight can also occur if our bodies are not functioning properly.</li>
                                <li> Overweight caused by thyroid and pituitary glands in our body is only 2%</li>
                                <li> Especially overweight is caused by eating too much food. If providing food beyond the digestive capacity will slow down the functioning of digestion and bring it to overweight and also by eating without proper timelines.</li>
                                <li> Overweight can be caused by psychological issues like anger, dissatisfaction, loneliness, family strife and love affairs.</li>
                                <li> As the percentage of fat increases in our body, shows the appearance of aged in face and leads to wasting of body. </li>
                            </ul>

                            <h5 className=" app-heading">How To Decrease The Fat  :-</h5>
                            <ul className='treatment-ortho-ul'><li> Everyone in the world is trying to lose weight in an effective way but failing to decrease the weight in a proper method from the root cause. </li>
                                <li> Kalpataru ayurveda hospitals, achieved a good result in many individuals for decreasing weight through a proper plan in natural way. In Men’s and women’s and also in children’s upto the age of 8-10 years, has been provided ayurveda treatment separately in all gender through the 6 modalities like ayurveda panchakarma and its upakramas treatment, Ahara, Vyayama, Dinacharya and Nidra. From our Kalpataru research and experiments has been done to decrease the weight for a full year on 500 people with the above methods, which yielded excellent results.</li>
                                <li> Ayurveda panchakarma and its upakramas treatment like Rukshana and Lekhana therapy. It includes udvartana (Abhyangam with medicated churna), Lekhana Basti, pralepa etc.</li>
                                <li> Kalpataru ayurveda has been provided treatment separately for vegetarian and non- vegetarians and observed results effectively. In non-vegetarians with the habit of drinking alcohol, smoking, there were no results for several months with the ayurveda treatment and in vegetarians; there have been successive results from the 40th day itself.</li>
                                <li> Moreover, Kalpataru ayurveda hospitals, through research have developed a successful plan for weight loss and to maintain the same weight after the treatment.</li>
                                <li> People, who want to lose weight, first have to get rid of bad habits and adopt a vegetarian lifestyle.</li>
                                <li> Kalpataru doctors have been studying the idea that the body is supporting therapeutic treatment.</li>
                                <li> Weight gain in our body is increasing gradually depending upon the nature of the body. It has been said that there will be depreciation also. For example, in a human body, 5kg weight increases per year; again it takes at least 6 months to reduce the same weight.</li>
                                <li> Our Kalpataru ayurveda hospitals has made it clear that all modern weight loss measures are contrary to nature as a result other organs are known to be vulnerable.</li>
                            </ul>


                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className='ortho-left-side'>
                                    <h4>Make An appointment </h4>
                                    <ul>
                                        <li> Monday-friday <span> : 9.00-17.00</span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9.00-16.00</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : closed</span></li>
                                        <b> <li>Nedd Urgent Help ? <span>9876543210</span></li></b>
                                    </ul>
                                    <div className='appointment-btn pt-10'>
                                        <span></span>
                                        <a href="#" class="main-btn">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                        <img src="/images/Obesity/obesity1.jpg" style={{"height":"200px"}} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>obesity</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                        <img src="/images/Obesity/obesity2.jpg" style={{"height":"200px"}} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Obesity</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                        <img src="/images/Obesity/obesity3.jpg" style={{"height":"200px"}} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Obesity</h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <br />
        </div>

    )
}
