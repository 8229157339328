import React from 'react'

function SummerCampOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>SUMMER <span> CAMP </span> INDIA</h1>
            </div>
            <div className='row mb-5' >
                <div className='summer_camp_p'>
                    <p>
                        To bring change in the period of student life, with the quest for 8-21 years children’s (Both males and females), every year national wide conducting a summer camp to educate them with 18 types of arts. Not only this, students are filling with courage and inspiration, educating the courage by roaming in KULUMANALI, HIMACHAL PRADESH and in Himalayas. In children’s making the self made ability, which increases the intellectual capacity, increases the digestion capacity by intake of ayurveda medicines. Started in 1999 as a nonprofit voluntary organization.
                    </p>
                    <p>
                        It Greatly Contributes to The Health and well being of citizens thus holding the ancient heritage, Vedic Culture and Traditional Educational Systems Intact at Kriya Yoga Samsthan, we firmly believe in giving your child what is best for their life and not what they expect best from us! At the GURUKUL, we take care of your child like you do.
                    </p>
                </div>
            </div>
            <div className='row mb-5' >
                <div className='summer_camp_p'>
                    <h3 className='summer_camp_title' >
                        6 REASONS WHY YOU SHOULD SEND YOUR CHILD TO THE RESIDENTIAL SUMMER CAMP  ?
                    </h3>
                    <p>
                        It is a Single Destination that gets kids outside having fun while teaching them lifelong skills.  It is a wonderful way to keep your children occupied in a way that helps them continue learning during the summer holidays.
                    </p>
                    <div className='mt-3 summer_camp_span' >
                        <span className='span_text'> || UNDERSTANDING OF OUR VEDIC CULTURE   ||  EXPLORING NEW ADVENTURES||  </span> <div></div>
                        <span className='span_text'> || NURTURING NEW FRIENDSHIPS||    || ABLE TO SOCIALIZE WITH OTHERS|| </span> <div></div>
                        <span className='span_text'> || DEVELOPING SELF-CONFIDENCE || A VARIETY OF SPORTS ACTIVITIES ||  </span> <div></div>
                    </div>
                    <div className='mt-5 summer_camp_link'>
                        FOR MORE DETAILS CAN VISIT WEBSITE : <a href='https://www.summercampindia.in/' target="_blank" > https://www.summercampindia.in/   </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SummerCampOne