import React from 'react'
import { Button, Card } from 'react-bootstrap';
import data from "../../../Data/BlogData.json";

function BlogOne() {
    return (
        <div className='container mb-3' >
            <div className="section_heading">
                <h1>Kalpataru <span> Blogs </span> Reviews</h1>
            </div>
            <div className='row' >
                {
                    data.map((d) => (
                        <div className='col-xl-4 mt-3' >
                            <Card>
                                <Card.Img variant="top" src={d.image} />
                                <Card.Body>
                                    <Card.Title> {d.title} </Card.Title>
                                    <Card.Text>
                                        {d.text}
                                    </Card.Text>
                                    <Button variant="primary">Go somewhere</Button>
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default BlogOne