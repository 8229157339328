import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function HomeAbout() {
    return (
        <div className="container homeabout-main">
            <div className="row" >
            <div className="section_heading">
                <h1>About <span>  Kalpataru Ayurveda </span> Hospital</h1>
            </div>
              {/*   <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12" >
                    <h3 className="homeabout-title"> About Kalpataru Ayurveda Hospital </h3>
                </div> */}
            </div>
            <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img className="homeabout-image" src="/images/home/therpy.jpg" alt="Loading.." />
                </div>
                <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                    <p> Established in 2000 at Hyderabad, Kalpataru Ayurveda Hospitals is under the aegis of Kriya Yoga Samasthan (WORLD VEDIC HEALTH SCIENCES PRIVATE LIMITED), a not-for-profit voluntary organization that was established in 1999 and instrumental in setting an example in creating awareness on individual, social, moral, psychological and physiological developments, thereby contributing to their well-being.</p>
                    <p>Kriya Yoga Samasthan (WVHS) is working under an age-old Arya Samaj Principles a reputed service-oriented organization, established by maharshi dayanda saraswati. </p>
                    <Link to="/about" className="about-link" >Know More</Link>
                </div>

            </div>
        </div>
    )
}
