import React from 'react'

export default function About() {
    return (
        <div className="container homeabout-main">
            <div className="row" >
                <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12" >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Ayurveda</span></h1>
                    </div>
                    {/* <h3> Ayurvedic Stores For Every One </h3> */}
                    {/* <h3 className="about-title text-center" > Kalpataru Ayurveda </h3> */}
                </div>
            </div>
            <div className="row justify-content-between mb-2">
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    <img src="/images/about/herb.jpg" className="homeabout-image" alt="..." />
                </div>
                <div className=" col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                    <p>A Non-profit voluntary organization established 21 years ago in the year 1999 have been a setting example in creating awareness on individual ,social, moral, psychological and physiological developments thus contributing in healthy and well being of citizens.</p>
                    <p> Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p>
                    <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p>
                    {/* <Link to="" className="about-link" >Know More</Link> */}
                </div>
            </div>
        </div>
    )
}
