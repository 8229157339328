import React from 'react'
import AboutStoreData from '../../../Data/AboutData/AboutStore.json'

export default function AboutStore() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Ayurvedic</span> Stores</h1>
                    </div>
                    {/* <h3> Ayurvedic Stores For Every One </h3> */}
                </div>
            </div>
            <div className='row' >
                <div className='col about-store-text'>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                ORGANIC MILLETS
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ FOXTAIL MILLET KORRALU</li>
                                <li>➤ LITTLE MILLET (SAMALU)</li>
                                <li>➤ PERAL MILLET (BAJRA)</li>
                                <li>➤ BROWNTOP (ANDAKORALU)</li>
                                <li>➤ ORGANIC  BROWN RICE</li>
                            </ul>
                            <ul>
                                <li>➤ PROSO MILLET( VARIGALU)</li>
                                <li>➤ KODO MILLET/ARIKALU</li>
                                <li>➤ BARNYARD (UDALU)</li>
                                <li>➤ FINGER MILLET ( RAGULU )</li>
                                <li>➤ SORGHUM (JONNALU)</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                PALM JAGGRIY  AND SUGER
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ TAATI BELLAM  G-1</li>
                                <li>➤ TAATI  KALAKANDA  G1 </li>
                                <li>➤ TAATI BELLAM  G 2</li>
                                <li>➤ TAATI KALAKANDA  –G2</li>
                                <li>➤ TAAATI HERBAL MIX</li>
                            </ul>
                            <ul>
                                <li>➤ TAATI BELLAM POWDER </li>
                                <li>➤  TAATI BELLAM G 3 </li>
                                <li>➤  TAATI LEAF BAGS </li>
                                <li>➤  TAATI SUGER </li>
                                <li>➤  TAATI SUGER </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                NAVA DHANYALU
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤  ULUVALU</li>
                                <li>➤ TUWAR DAL (KANDULU)</li>
                                <li>➤ MOONG DAL (PESALU)</li>
                                <li>➤ URAD DAL (MINUMULU )</li>
                                <li>➤ YAVALU  (BARLEY)</li>
                                <li>➤ YAVALU  (BARLEY)</li>
                            </ul>
                            <ul>
                                <li>➤ CHANNA (SHANIGALU)</li>
                                <li>➤ NUVVULU</li>
                                <li>➤ BABBERUL U (COWPEA SEED)</li>
                                <li>➤ GODHUMALU (WHEAT)</li>
                                <li>➤ MAKKALU (CORN)</li>
                                <li>➤ ANUMULU (FAVA)</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-xl-6'>
                    <div className='row' >
                        <div className='row mt-4 mb-4' >
                            <div className='col about_store_col_title' >
                                ORGANIC KITCHEN PRODUCTS
                            </div>
                        </div>
                        <div className='col-xl-12 about_store_col' >
                            <ul>
                                <li>➤ JEERA ( JILAKARRA) </li>
                                <li>➤ CHILLI POWDER  </li>
                                <li>➤ AVAALU </li>
                                <li>➤ MENTULU </li>
                                <li>➤ HALDI POWDER </li>
                                <li>➤ MENTULU </li>
                            </ul>
                            <ul>
                                <li>➤ AJWAIN  </li>
                                <li>➤ DHANIYA POWDERS  </li>
                                <li>➤ GINGER POWDER  </li>
                                <li>➤ DRY GINGER </li>
                                <li>➤ GINGER PASTE  </li>
                                <li>➤ DRY CHILLI  </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
