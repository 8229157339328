import React from 'react'


export default function Treatment1() {
    return (
       
    <div className="section_heading">
        <h1>Kalpataru <span>Sciatica Pain </span> Treatment</h1>
      
  
      </div>

       
    )
}
