import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Back3() {
    return (



        <section className="Kalpataru-second-ortho pt-5">

            <div className="container-fluid">

                <div className="row mt-30">
                    <div className="col-md-3 col-sm-6">
                        <a href=""> <div className="ortho-card">
                        <img src="/images/Backpain/backpain1.jpg" style={{"height":"200px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Back Pain</p>
                            </div>
                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/Backpain/backpain2.jpg" style={{"height":"200px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Back Pain</p>
                            </div>
                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/Backpain/backpain3.jpg" style={{"height":"200px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Back Pain</p>
                            </div>
                        </div></a>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <a href=""><div className="ortho-card">
                        <img src="/images/Backpain/backpain4.jpg" style={{"height":"200px"}} alt="Image" />
                            <div className="ortho-content">
                                <p>Back Pain</p>
                            </div>
                        </div></a>
                    </div>
                </div>
            </div>

        </section>





    )
}
