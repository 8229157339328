import React from 'react'
import data from "../../../Data/Accomodation/AccData.json"


export default function AccomodationOne() {
    return (
        
        <div className='container' >
         {/*    <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Accomodation</span></h1>
                    </div>
                </div>
            </div> */}
          {/*   <div className='row' >
                <div className='col about-store-text' >
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </div>
            </div> */}
            <div className='row' >
                <div className='col' >
                    <h6 className="about-two-title" >Our Facilities</h6>
                </div>
            </div>
            <div className='row' >
                {
                    data.map((d) => (
                        <div className='col-xl-12 mt-2 program_two_col' >
                            <h3  className='accomodation'> {d.title} </h3>
                            <p  className='accp'> {d.text} </p>
                        </div>
                    ))
                }

            </div>
            
           {/*  <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'> Reception (Front Office) / May I Help You Desk : </h3>
                    <p className='accp'>
                    Front Office will be available with competent staff ( 1 Male and 1 Female) having good communication skills. They will work shifts (6 am - 10 pm). 
                    Services available at the hospital will be displayed at the inquiry. Names and contacts of responsible persons like the Hospital In-Charge, PRO will be displayed.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Therapy rooms For Clinics :</h3>
                    <p className='accp'>
                    For Ayurveda Treatments, we have facilities like hot water and steam will be provided where the patients can peacefully take treatments. There is also Surgery Room, Vasti Rooms, and Massage rooms.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Outpatient facility (OP) : </h3>
                    <p  className='accp'>
                    OP facility in the hospital is an essential part and is designed for outpatients to meet with the doctors and have consultations regarding their health issues and available all 6 days a week. Yoga zone, Bathing Salty, and Dressing Rooms are also available. 
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>In-patient facility (IP) : </h3>
                    <p className='accp'>
                    An individual may be required to be admitted for treatment or special medication on the advice of a physician. In such cases rooms are available. We have Special Rooms, VVIP Rooms, Treatment Rooms, and Dining Halls. 
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Traditional kitchen :</h3>
                    <p className='accp'>
                    We have our own in-house kitchen where we prepare and provide healthy and nutritious food to patients on the advice of our Physician. No outside food is allowed. Food will be prepared according to the patient's diet plan. There is a separate cook to monitor various aspects of food given to the patients. We use bio-mass cooking. We cook in brass, copper, bronze, silver, clay, and mud cooking utensils. We prepare purely organic food. 
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Pharmacy :</h3>
                    <p className='accp'>
                    Our in-house pharmacy has a wide variety of herbal products for you to use and buy. We prepare our medicine. Organic food products and KALPA SOUNDARYA beauty products are also available.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Experienced Doctors :</h3>
                    <p className='accp'>
                    We believe that it’s about curing the patient always and not only the illness, we have physicians and doctors who understand your health and give proper consultation for medication and treatment to give you the healthy life that you want. We have with us experienced Ayurvedic Physicians, Pediatric, Panchakarma & Rasasastra Specialist, ENT, Shalya Chikitsa, Chiropractic –cardiac / MBBS, Physiotherapist, and Spine/Neuro Care doctors.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Safety measures :</h3>
                    <p className='accp'>
                    We always put your safety as our priority. We have Fire Safety, Emergency care, and Ambulance at your disposal 24/7.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Security :</h3>
                    <p className='accp'>
                    We have secured CCTV surveillance which will be monitored around the clock. Also for every 2 hours, we manually observe the area to prevent mishaps. 
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Lab :</h3>
                    <p className='accp'>
                    We have a well-equipped laboratory for conducting all types of tests for Example Bleed, Urine etc. We have excellent X-Ray Scanning rooms.( or other diagnostic Centers  depending on emergency ).
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Library :</h3>
                    <p className='accp'>
                    We have a huge collection of printed resources at our library which includes Health related Ayurveda,Yoga,Nature Cure and Modern medical science  books, and Ayurveda Samhita's Vedic books available in English / Hindi and local languages. 
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Preparation room :</h3>
                    <p className='accp'>
                    In the Preparation room, we prepare all the medicines pertaining to the diseases as prescribed by the physician which includes the preparation of essential oils and powders and many more.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Storeroom :</h3>
                    <p className='accp'>
                    In the Storeroom, all ayurvedic raw materials, herbal powders, Ayurvedic Medicines, and General Oils will be available.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Customer care :</h3>
                    <p className='accp'>
                    Our Staff and Physicians will always be available 24/7 to attend to your queries through phone calls, emails, Whatsapp, and Live Chats and offer consultation through Phone or Video as per your convenience.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Maintenance :</h3>
                    <p className='accp'>
                    We have a very good team of Housekeeping	and Maintenance staff. They take good care in keeping the premises clean all the time, especially prioritizing Kitchen Cleanliness and Patients food service. 	
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Home service :</h3>
                    <p className='accp'>
                    We offer daily home service and Ambulance Service (Pick Up & Drop). We will also be sending our therapist to the patient’s home for treatment. This will be done at the special request of patients and as per the physician’s advice.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Specialties :</h3>
                    <p className='accp'>
                    We specialize in treatments of Back pain, Neck pain, Sciatica pain, Knee pain, Diabetes, Blood pressure, Thyroid, Asthma,  Neuro, Liver disorders, Stomach disorders, Bowl disorders, Lung disorders, Paralysis, Parkinson, Stress, Cancer, Kidney Problems and Heart Diseases.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Other services :</h3>
                    <p className='accp'>
                    We have technicians who are always with us. We have Plumbers and Electricians. We have all-time Water services and Staff Accommodations are also available (Male & Female separate).
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Manufacture /packing : </h3>
                    <p className='accp'>
                    We produce our own Ayurvedic products which include Organic vegetables, Organic Cosmetics, Raw Products, Patient Treatment Oils & Cow Ghee, and make them available to you in packets.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Bookings :</h3>
                    <p className='accp'>
                    We have technicians who are always with us. We have Plumbers and Electricians. We have all-time Water services and Staff Accommodations are also available (Male & Female separate).
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Publicity :</h3>
                    <p className='accp'>
                    We have a strong reputation in print media and an established online presence and we are available on Google search / YouTube/ Facebook / etc.

                    </p>
                </div>
            </div>
               <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Diagnosis :</h3>
                    <p className='accp'>
                    We have facilities for taking all kinds of tests and providing precise information about patients' health conditions. We have IriDiagnosis, Traditional Diagnosis, And Modern Diagnosis.

                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'>Camping :</h3>
                    <p className='accp'>
                    : we are conducting Health Awareness Camps, Treatments Camps Yoga Camps at Metropolitan cities in india We are Conducting Camp around 10-30 Days Program Under supervision of  experienced Yoga Experts And Volunteers.   
                    </p>
                </div>
            </div>
              <div className='row' >
                <div className='col about_two_col' >
                    <h3 className='accomodation'> Exhibition:</h3>
                    <p className='accp'>
                    We are Conducting AYURVEDA EXPO Since 2011 We Create Awareness On Ayurveda herbal Plants, Herbal Medicine, Organic products, Free Health Check up and many more Activities For 10 Days
                    </p>
                </div>
            </div> */}
        </div>
    )
}
