import React from 'react'

function SwarnaBinduOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>SWARNA <span> BINDU </span> PRASHANAM</h1>
            </div>
            <div className='row mb-5' >
                <div className='col-xl-6 summer_camp_p' >
                    <p>
                        In Ayurveda, charaka,Susrutha, kashyapa maharshis described in sutra sthana that humans are born with swarnamsha at the time of birth only. During childhood from the age of 0-16 years, this swarnamsha gets hinted in brain and helps in development of MEDHA SHAKTI, SMARANA SHAKTI (Remenbrance Power), DHATUVRUDDHI, SHRUTA DHARUTVA SHAKTI, physical and mental energy.
                    </p>
                    <p>
                    In today’s modern life policy, this “SWARNAMSHU” gets extinct from brain of children born before reaching the young age and being targeted to chronic diseases. Parents with improper awareness are using the different types of antibiotics and children’s are facing those side effects. 
                    </p>
                </div>
                <div className='col-xl-5' >
                    <img className='yoga_camp_img' src='/images/events/swarna/swarna.png' />
                </div>
            </div>
        </div>
    )
}

export default SwarnaBinduOne