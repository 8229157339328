import React from 'react'
import Progress from '../HomePageContainer/Progress'

export default function Abouthospital() {
  return (
    <div className="section_heading">
      <h1>Kalpataru <span> Ayurveda</span> Hospitals</h1>
      <div>
        <Progress/>
      </div>
      <div className="container homeabout-main">
        <div className="row justify-content-between">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <img src="/images/about/herb.jpg" className="homeabout-image " alt="..." />
          </div>
          <div className=" col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 homeabout-col">
            <p>  A Non-profit voluntary organization established 21 years ago in the year 1999 have been a setting example in creating awareness on individual ,social, moral, psychological and physiological developments thus contributing in healthy and well being of citizens.</p>
            <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p>
            <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p>
            {/* <Link to="" className="about-link" >Know More</Link> */}
          </div>

        </div>
      </div>

    </div>


  )
}
