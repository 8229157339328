import React from 'react'
import { Link } from 'react-router-dom'
import data from "../../../Data/AboutData/AboutHospitalOne.json"

export default function AboutHospitalOne() {
    return (
        <div className='container' >
            <div className='row' >
                {
                    data.map((d) => (
                        <div className='card mb-4'>
                      
                        <div className='col-lg-12' >
                            <div className='single-feature' >
                                <div className='feature-head'>
                                   
                                    <h4> {d.title} </h4>
                                </div>
                                <div className='feature-content' >
                                    <p> {d.text} </p>
                                    <p> {d.text1} </p>
                                    <p>{d.text2}</p>
                                    <p>{d.text3}</p>
                                    <p>{d.text4}</p>
                                    {/* <div className='feature-btn' >
                                        <Link href="#" className="f-btn"> {d.link} </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        </div>
                        
                    ))
                }

            </div>
        </div>
    )
}
