import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function AboutTwo() {
    return (
        <Container>
            <Row>
                <Col> <h6 className="about-two-title" >Our Values</h6> </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='about-two-ul'>
                        {/* <li>To propagate authentic &amp; shastroktha knowledge of ayurveda, ayurveda panchakarma, and rejuvenation therapies.</li>
                        <li> To treat various diseases &amp; disorders using the basic principles of ayurveda and Panchakarma therapy.</li>
                        <li> To improve the quality of life for those with chronic ailments. </li>
                        <li> To cure those various ailments where the cure is not possible in other fields of medicine, but only through research in ayurveda. </li>
                        <li> To educate the common man and disseminate knowledge treasure of ayurveda- the mother of all the sciences. </li>
                        <li> To conduct research and educational activities in understanding and advancing in the treatment of those chronic and debilitating conditions through research in ayurveda and  </li> */}
                        <li>Discipline and code of conduct</li>
                        <li>Ethics and Dignity</li>
                        <li>Excellence and perfection</li>
                        <li>Honesty and sincerity</li>
                        <li>Respect and Tolerance</li>
                       <p className='cbh'>Introducing the society to other types of programs that seek the upliftment of the society and working for its betterment. However, financial strength is essential for all the above programs. The organization so far has not collected any donations and made the aforesaid gardens possible. We have been successfully implementing these constructive programs for the last 18 years with the revenue generated by a self-effort. The benefits of the above programs, services, etc. are fully understood by those who are subject to the terms and conditions of the organization. We hope that we will endeavor to build the economy legitimately with self-reliance and serve the upliftment of the society till the end. If you like our company's ideas, be a partner in the organization and move forward in all fields and empower the youth with a bright future.</p>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}
