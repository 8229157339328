import React from 'react'


export default function Obesity2() {
  return (

    /*     <div className='container'>
        <div className='row'>
          <div className='col-sm-4 pt-3 '>
            <div className='card box-shadow'>
              <div className='obesity'>
              <img src='https://media.istockphoto.com/photos/weight-loss-success-picture-id153048232?k=20&m=153048232&s=612x612&w=0&h=B2fSduT5KGZ9m4VAKq24Ky4TwdVL4W61i8fEM1xe-K8=' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Over weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
          <div className='col-sm-4 pt-3'>
            <div className='card box-shadow'>
               <div className='obesity'>
              <img src='https://thumbs.dreamstime.com/b/fat-women-suffer-obesity-big-hamburger-apple-hands-junk-food-concept-woman-gray-background-73918587.jpg' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Under weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
          <div className='col-sm-4 pt-3'>
            <div className='card box-shadow'>
               <div className='obesity'>
              <img src='https://media.istockphoto.com/photos/weight-loss-success-picture-id153048232?k=20&m=153048232&s=612x612&w=0&h=B2fSduT5KGZ9m4VAKq24Ky4TwdVL4W61i8fEM1xe-K8=' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Over weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
        </div>
       
      </div> */
    <div>
      <div className="container homeabout-main pt-5">
        <div className="row justify-content-between">

          <div className=" col-xl-6 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
            <p> <b>Weight loss and stay fit naturally through panchakarma with a combination of massage, detoxifying techniques, life style changes, diet, yogic exercise and some special medications.</b></p>
            <p> ➤ In human body, the seven fundamental principles that support the basic structure of the body are Rasa (plasma), Rakta (blood), Mamsa (muscle), Medo (fat), Asthi (bone), Majja (bone marrow), Sukra (sperm) and the eighth Dhatu is known as Ojas. In our body, it takes about 22-24 days to make a fat substance called adipose tissue, because the food we eat becomes Rasa Dhatu in 24 hours and in that rasa heena bhaga excretes in the form of Mala, Mutra.</p>
            {/*  <p> ➤ Then takes place the formation of Rakta Dhatu from rasa Dhatu (Rasa Dhatu => Rakta Dhatu -> Mamsa Dhatu -> Medo Dhatu -> Asthi Dhatu -> Majja Dhatu -> Sukra Dhatu). Totally 42 days is required in our body to form above Saptadhatus, in which it takes 21 days to make the fat tissue which indirectly provides nourishment and support to all the parts of our body.</p> */}
            <p> ➤ Adipose tissue also plays an important role in our body.</p>
            <p> ➤	Fat is derived from the food we eat, which provides smoothness in between the bones, to flex the body parts, also gives good energy when we do not feed our body and also gives nourishment to liver, gall bladder, pancreas and lastly to muscle tissues.</p>
            <p> ➤ 	These adipose fats travels in blood vessels, stores the fat cells where we needed and utilizes the stored fat whenever are necessary.</p>

          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <img className="homeabout-image" src="https://assets.thehansindia.com/h-upload/2021/03/04/1035280-obesity-day.webp" height="290px" alt="Loading.." />
          </div>

        </div>
      </div>
    </div>


  )
}
