import React from 'react'

function FranchiseOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>Kalpataru <span> Franchise </span> Plans</h1>
            </div>
            <div className='row' >
                <div className='col franchise_title' > Kalpataru Ayurveda Hospital Franchise Opportunity </div>
            </div>
            <div className='row' >
                <div className='col franchise_title_one mt-3 mb-3' >  ( A Unit Of  World Vedic Health Sciences Private Limited ) </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p'>
                    <p>
                        At Kalpataru Ayurveda Hospitals, we understand that spine, bone, and pain disorders can be lifetime illnesses. Established in 2009, We have been successfully treating people with those disorders under the expertise of Maharishi Dayananda Saraswathi Kriya Yoga Samsthan, Hyderabad.  is a pioneer and non-profit voluntary organization which was established in 1999. For the past 21 years, they have been delivering treatment with Ayurveda, Yoga, and other therapies to empower all to live a life of natural balance. They work with our patients to reduce pain and improve their quality of life.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p'>
                    <p>
                        Emphasizing the time-tested age-old integrated medical practices and techniques, we are overwhelmed by the response pouring in from across the nation, seeking our therapeutic procedures. Due to time, space, and distance constraints, on most occasions, we attend on-call consultations with patients, besides treatment at our hospital. We have offered treatment to more than 20, 000 patients in the past 11 years who sent thanks notes and referred their acquaintances. Due to the overwhelming response, we resolved to expand Our PAN-INDIA Network through a collaborative and franchise operation. Shortly, the details will be notified on our website.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col summer_camp_p'>
                    <p>
                        We are inviting franchisees so that most people can benefit from our remedies and therapies.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FranchiseOne