import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import data from "../../../Data/contact.json";

function HomeContactOne() {
    return (
        <div className='container' >
            <Row className='mb-5 mt-5'>
                {
                    data.map((d) => (
                        <Col xl={4} lg={4} xs={12} md={6} className='mt-2' >
                            <Card>
                                <Card.Header className="add_card_title" > <img className="home-contact-img" src="/images/icons/home.png" alt="Loading.." /> {d.title} </Card.Header>
                                <Card.Body>
                                    <Card.Title className="add_card_title_one" > {d.titleOne} </Card.Title>
                                    <Card.Text className="add_card_text"> {d.text} </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }

            </Row>
        </div>
    )
}

export default HomeContactOne