import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';



export default function Paralysis4() {
    return (

        <div>
            <div class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                        <div className='orthocare-desc'>
                            <h5 class=" app-heading">About Paralysis :-</h5>
                            <p className="para-text">  ➤ “World stroke day” is known every year on October 29th.</p>
                            <p className="para-text"> ➤ In ayurveda, paralysis can be comparable to pakshaghata. Paksha; means a part of a body, ghatam; is a blow</p>
                            <p className="para-text"> ➤ From the past few (5-10) years, people with high blood pressure are more susceptible to this disease, and due to rupture of blood vessels, decreased oxygen levels in the blood, high cholesterol in body, in addition when accidents occur, the head is severely damaged and brain is paralyzed when bleeding occurs.</p>
                            <p className="para-text"> ➤ After the attack of stroke in our body, per second approximately 15-20 lakhs blood cells are dies. When strokes occur in left hemisphere of the brain, will show right sided paralysis. When strokes occurs in right hemisphere of the brain will show left sided paralysis like left side of the face, an arm and a leg are curled.</p>

                            <h5 className='app-heading'>Causes :- </h5>
                            <ul className='treatment-ortho-ul'><li> Stroke main causes are alcohol, smoking, emotional stress, high blood pressure, high cholesterol, heart disease, diabetes, hereditary, use of anticoagulants, lack of physical activity, insomnia, non adherence to dietary supplements, mental disability, severe anger. </li>

                            </ul>
                            <h5 className='app-heading'>Symptoms :-</h5>
                            <ul className='treatment-ortho-ul'><li>Usually In paralysis the face becomes curled, with one arm weak in both hands.</li>
                                <li> Slurred speech</li>
                                <li> Severe headache</li>
                                <li> Dysphasia </li>
                                <li> Decreased vision</li>
                                <li> Numbness</li>
                                <li> Gait abnormality</li>
                                <li> Lack of taste in tongue</li>
                                <li> Body tiredness</li>
                                <li> Being exposed to emotions</li>
                                <li> Memory loss</li>
                            </ul>

                            <h5 class=" app-heading">CHIKITSA 	 :-</h5>
                            <p className="para-text">  ➤ Emergency treatment is essential at least 3 hours after the onset of stroke. At first contact your neurologist immediately and treatment is required to get the whole body under control because blood vessels in the brain may die.</p>
                            <p className="para-text">  ➤ Modern doctors for this condition, within 48 hours of emergency care treatment is given after the disease intensity is recognized through the reports of CT scan, ECG, LIPID profile, CVD.</p>
                            <p className="para-text">  ➤ The disease may then have to be relied upon physiotherapy and tablets.</p>
                            <p className="para-text">  ➤ From the past 11 years (2009), Kalpataru ayurveda hospitals have started ayurveda panchakarma treatment for those suffering from paralysis mainly for stroke patient who received emergency treatment immediately and discharged. Initially ayurveda home treatment was given to the paralyzed patient for 40 days in some zones of Telangana and achieved excellent results. </p>
                            <p className="para-text">  ➤ In an immovable patient after the emergency treatment from corporate hospital, got recovered within 40 days of ayurvedic treatment. It is a remarkable that there is a recovery of 90% in 3 months. Among the 10 patients separate outcomes were achieved after the ayurvedic treatment.</p>
                            <p className="para-text">  ➤ 100% of results are possible with our ayurvedic treatment for those who come immediately after the emergency care. 90% of results, if treated within 3 months of attack of paralysis, 80% results within 1 year.</p>
                            <p className="para-text">  ➤ There is no cure for this disease after a year, even though ayurveda treatment is provided just to flex the body, to bring the flexibility in legs, hands and waist. Except that no other results were found.</p>
                            <p className="para-text">  ➤ There is a specialty in Kalpataru ayurveda treatment methods, depending upon the physiological nature of each patient disease, dietary supplements, meditation & medicines and ayurveda panchakarma treatment method is available.</p>
                            <p className="para-text">  ➤ To recover quickly after the onset of pakshaghata symptoms, everyday goat milk or lambs milk is to be heated and diluted with water, should drink in the morning and evening. Strong drugs like brahmi, ashwagandha, amla, Saraswathi and palm Jaggery, fruits, cow’s ghee is to be provided.</p>
                            <p className="para-text">  ➤ In Kalpataru ayurveda chikitsa, minimum 30 days treatment in 1st session is planned then, depending upon the intensity of the disease, 2nd session may start. Whatever it may be, treatment is to be taken as soon as possible. </p>
                            <p className="para-text">  ➤ When the plant is dead, watering does not benefits, so ayurvedic treatment should be sought immediately after the onset of the disease. </p>
                            <ul className='treatment-ortho-ul'>

                                <li>Ayurvedic internal medicine</li>
                                <li>Panchakarma</li>
                                <li>Upakarmas of panchakarma</li>
                                <li>Ahara niyama (dietary regimen)</li>
                                <li>Vyayama (yogic exercise)</li>
                                <li>Chairopractic</li>
                                <li>Marma Therpay </li>
                                <li>Massage Therapy </li>
                                <li>Nadi therapy </li>
                                <li>Consoling </li>
                            </ul>


                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-6'>
                                <div className='ortho-left-side'>
                                    <h4>Make An appointment </h4>
                                    <ul>
                                        <li> Monday-friday <span> : 9.00-17.00</span></li>
                                        <li>Saturday<span style={{ marginLeft: '42px' }}> : 9.00-16.00</span></li>
                                        <li>Sunday <span style={{ marginLeft: '51px' }}> : closed</span></li>
                                        <b> <li>Nedd Urgent Help ? <span>9876543210</span></li></b>
                                    </ul>
                                    <div className='appointment-btn pt-10'>
                                        <span></span>
                                        <a href="#" class="main-btn">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-6 pt-3'>
                                <div className='ortho-card-secondpart '>

                                    <div className='ortho-image-part'>
                                        <div className='image'>
                                        <img src="/images/paralysis/sideparalysis1.jpg" style={{"height":"180px","width":"293px"}} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Paralysis</h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>:
                                        <img src="/images/paralysis/sideparalysis2.png" style={{"height":"180px","width":"290px"}} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Paralysis </h4>
                                        </div>
                                    </div><br />
                                    <div className='ortho-image-part '>
                                        <div className='image'>
                                        <img src="/images/paralysis/sideparalysis3.jpg" style={{"height":"180px","width":"293px"}} alt="Image" />
                                        </div>
                                        <div className='cont'>
                                            <h4>Paralysis </h4>
                                        </div>
                                    </div><br />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <br />
        </div>


    )
}
