import React from 'react'
import { Button } from 'react-bootstrap'
import ExpoData from "../../../Data/Events/ExpoOne.json"

function ExpoOne() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col text-center mb-3' >
                    <h4> OUR CUSTOMISED CORPORATE WELLNESS PROGRAMS INCLUDE: </h4>
                </div>
            </div>
            <div className='row mb-5' >
                {
                    ExpoData.map((d) => (
                        <div className='col-xl-3' >
                            <div className="card h-100">
                                <img src={d.image} class="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title expo_one_title"> {d.title} </h5>
                                    <p className="card-text expo_col"> {d.des} </p>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
            <div className='row mb-5 ' >
                <div className='col' >
                    <div className="card bg-dark text-white ">
                        <img src="/images/events/expo_bg.jpg" class="card-img" alt="..."/>
                            <div className="card-img-overlay expo_one_row_bg">
                                <h5 className="card-title expo_one_title"> Book Expo Slot </h5>
                                <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                <Button className='expo_one_btn'> Book Expo </Button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpoOne